import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";
import { BusinessMediaLibraryApi, MediaAssetAPI } from "../../services/business.services";
import { GlobalErrorDialogType, RatingDialogShownSourceType } from "../constants";

export interface UIState
{
  audioPlayerTrack?: MusicTrack,
  audioPlayerPlaying?: boolean,
  conversation: ConversationUIState,
  media_library: MediaLibraryState,
  upsellShown: boolean;
  paymentDialogOpen: boolean;
  paymentConfirmationOpen: boolean;
  progressState?: ProgressState;
  hasSeenExtendedPlan?: boolean;
  plan: PlanUIState;
  hasSeenPostStrategyQuiz?: boolean;
  showAddMultipleBusinessesUpsell: boolean;
  requestEditorReloadWithAudioTimestamp?: number;
  requestEditorPauseAudioTimestamp?: number;
  requestEditorStopAnimationTimestamp?: number;
  editorAnimationCompleteTimestamp?: number;
  editorAudioStartTimeOverrideMs?: number;
  editorDesignAudioNotAllowed?: boolean;
  globalErrorDialogType?: GlobalErrorDialogType;
  showRatingWarmup?: boolean;
  ratingWarmupSource?: RatingDialogShownSourceType;
}

export interface MusicTrack
{
  url: string;
  startTimeInSeconds?: number;
}

export interface MediaLibraryState
{
  media_assets: MediaAssetAPI[];
  next_page?: number
}

interface ConversationUIState
{
  yield_to_drawer: boolean;
  last_yield_to_drawer_time: number;
  pending_draft_post_idea_id?: string;
}

interface PlanUIState
{
  lastDateClicked?: string;
  lastPostIdeaEdited?: string;
}

export interface ProgressState
{
  progress?: number;
  message?: string;
}

const initialState: UIState = {
  audioPlayerTrack: undefined,
  audioPlayerPlaying: false,
  conversation: {
    yield_to_drawer: false,
    last_yield_to_drawer_time: 0,
  },
  media_library: {
    media_assets: [],
  },
  upsellShown: false,
  paymentDialogOpen: false,
  paymentConfirmationOpen: false,
  plan: {},
  showAddMultipleBusinessesUpsell: false,
};

export const uiSlice = createSlice( {
  name: 'ui',
  initialState,
  reducers: {
    setAudioPlayerTrack: ( state, action: PayloadAction<MusicTrack | undefined> ) =>
    {
      return {
        ...state,
        audioPlayerTrack: action.payload,
      };
    },
    setConversationYieldToDrawer: ( state, action ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          yield_to_drawer: action.payload,
        }
      }
    },
    setConversationLastYieldToDrawerTime: ( state, action: PayloadAction<number> ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          last_yield_to_drawer_time: action.payload,
        }
      }
    },
    setAudioPlayerPlaying: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        audioPlayerPlaying: action.payload,
      };
    },
    clearAndStopAudioPlayerTrack: ( state ) =>
    {
      return {
        ...state,
        audioPlayerTrack: undefined,
        audioPlayerPlaying: false,
      };
    },
    setPendingDraftPostIdeaId: ( state, action: PayloadAction<string> ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          pending_draft_post_idea_id: action.payload
        }
      }
    },
    clearPendingDraftPostIdeaId: ( state ) =>
    {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          pending_draft_post_idea_id: undefined
        }
      }
    },
    clearUI: () =>
    {
      return {
        ...initialState
      };
    },
    addToMediaLibrary: ( state, action: PayloadAction<BusinessMediaLibraryApi> ) =>
    {
      const existingMediaLibrary = state.media_library || {
        media_assets: [],
        next_page: undefined
      };
      return {
        ...state,
        media_library: {
          ...existingMediaLibrary,
          media_assets: [...existingMediaLibrary.media_assets, ...action.payload.media_assets],
          next_page: action.payload.next_page,
        }
      }
    },
    clearMediaLibrary: ( state ) =>
    {
      return {
        ...state,
        media_library: {
          media_assets: [],
          next_page: undefined
        }
      }
    },
    setUpsellShown: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        upsellShown: action.payload,
      };
    },
    setShowAddMultipleBusinessesUpsell: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        showAddMultipleBusinessesUpsell: action.payload,
      };
    },
    setPaymentDialogOpen: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        paymentDialogOpen: action.payload,
      };
    },
    setPaymentDialogSelectedSubscriptionDuration: ( state, action: PayloadAction<string> ) =>
    {
      return {
        ...state,
        paymentDialogSelectedSubscriptionDuration: action.payload,
      };
    },
    setPaymentConfirmationOpen: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        paymentConfirmationOpen: action.payload,
      };
    },
    setProgressState: ( state, action: PayloadAction<ProgressState> ) =>
    {
      const existingProgressState = state.progressState;
      return {
        ...state,
        progressState: {
          ...existingProgressState,
          ...action.payload,
        }
      };
    },
    clearProgressState: ( state ) =>
    {
      return {
        ...state,
        progressState: undefined,
      };
    },
    setPlanLastDateClicked: ( state, action: PayloadAction<string> ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastDateClicked: action.payload
        }
      }
    },
    clearPlanLastDateClicked: ( state ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastDateClicked: undefined
        }
      }
    },
    setPlanLastPostIdeaEdited: ( state, action: PayloadAction<string | undefined> ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastPostIdeaEdited: action.payload
        }
      }
    },
    clearPlanLastPostIdeaEdited: ( state ) =>
    {
      return {
        ...state,
        plan: {
          ...state.plan,
          lastPostIdeaEdited: undefined
        }
      }
    },
    setHasSeenPostStrategyQuiz: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        hasSeenPostStrategyQuiz: action.payload,
      };
    },
    setRequestEditorReloadWithAudioTimestamp: ( state, action: PayloadAction<number> ) =>
    {
      return {
        ...state,
        requestEditorReloadWithAudioTimestamp: action.payload,
      };
    },
    clearRequestEditorReloadWithAudioTimestamp: ( state ) =>
    {
      return {
        ...state,
        requestEditorReloadWithAudioTimestamp: undefined,
      };
    },
    setRequestEditorPauseAudioTimestamp: ( state, action: PayloadAction<number> ) =>
    {
      return {
        ...state,
        requestEditorPauseAudioTimestamp: action.payload,
      };
    },
    clearRequestEditorPauseAudioTimestamp: ( state ) =>
    {
      return {
        ...state,
        requestEditorPauseAudioTimestamp: undefined,
      };
    },
    setRequestEditorStopAnimationTimestamp: ( state, action: PayloadAction<number | undefined> ) =>
    {
      return {
        ...state,
        requestEditorStopAnimationTimestamp: action.payload,
      };
    },
    setEditorAnimationCompleteTimestamp: ( state, action: PayloadAction<number> ) =>
    {
      return {
        ...state,
        editorAnimationCompleteTimestamp: action.payload,
      };
    },
    clearEditorAnimationCompleteTimestamp: ( state ) =>
    {
      return {
        ...state,
        editorAnimationCompleteTimestamp: undefined,
      };
    },
    setEditorAudioStartTimeOverrideMs: ( state, action: PayloadAction<number> ) =>
    {
      return {
        ...state,
        editorAudioStartTimeOverrideMs: action.payload,
      };
    },
    clearEditorAudioStartTimeOverrideMs: ( state ) =>
    {
      return {
        ...state,
        editorAudioStartTimeOverrideMs: undefined,
      };
    },
    setEditorDesignAudioNotAllowed: ( state, action: PayloadAction<boolean> ) =>
    {
      return {
        ...state,
        editorDesignAudioNotAllowed: action.payload,
      };
    },
    clearEditorDesignAudioNotAllowed: ( state ) =>
    {
      return {
        ...state,
        editorDesignAudioNotAllowed: undefined,
      };
    },
    showGlobalErrorDialog: ( state, action: PayloadAction<GlobalErrorDialogType> ) =>
    {
      return {
        ...state,
        globalErrorDialogType: action.payload,
      }
    },
    hideGlobalErrorDialog: ( state ) =>
    {
      return {
        ...state,
        globalErrorDialogType: undefined,
      }
    },
    setShowRatingWarmup: ( state, action: PayloadAction<RatingDialogShownSourceType> ) =>
    {
      return {
        ...state,
        showRatingWarmup: true,
        ratingWarmupSource: action.payload,
      }
    },
    clearShowRatingWarmup: ( state ) =>
    {
      return {
        ...state,
        showRatingWarmup: false,
        ratingWarmupSource: undefined,
      }
    }
  }
} )

export function getCurrentMusicTrack( state: RootState )
{
  return state.ui.audioPlayerTrack;
}

export function getRequestEditorReloadWithAudioTimestamp( state: RootState )
{
  return state.ui.requestEditorReloadWithAudioTimestamp;
}

export function getRequestEditorPauseAudioTimestamp( state: RootState )
{
  return state.ui.requestEditorPauseAudioTimestamp;
}

export function getRequestEditorStopAnimationTimestamp( state: RootState )
{
  return state.ui.requestEditorStopAnimationTimestamp;
}

export function getCurrentMusicTrackPlaying( state: RootState )
{
  return state.ui.audioPlayerPlaying;
}

export function getShouldConversationYieldToDrawer( state: RootState )
{
  return state.ui.conversation.yield_to_drawer;
}

export function getConversationLastYieldToDrawer( state: RootState )
{
  return state.ui.conversation.last_yield_to_drawer_time;
}

export function getPendingDraftPostIdeaId( state: RootState )
{
  return state.ui.conversation.pending_draft_post_idea_id
}

export function getProgress( state: RootState )
{
  return state.ui.progressState;
}

export const getMediaAssets = ( state ) => state.ui.media_library.media_assets;
export const getMediaLibraryNextPage = ( state ) => state.ui.media_library.next_page;
export const getUpsellShown = ( state ) => state.ui.upsellShown;
export const getShowAddMultipleBusinessesUpsell = ( state ) => state.ui.showAddMultipleBusinessesUpsell;
export const getPaymentDialogOpen = ( state ) => state.ui.paymentDialogOpen;
export const getPaymentConfirmationOpen = ( state ) => state.ui.paymentConfirmationOpen;
export const getPlanLastDateClicked = ( state ) => state.ui.plan.lastDateClicked;
export const getPlanLastPostIdeaEdited = ( state ) => state.ui.plan.lastPostIdeaEdited;
export const hasSeenPostStrategyQuiz = ( state ) =>
{
  if ( !!state.ui.hasSeenPostStrategyQuiz )
  {
    return state.ui.hasSeenPostStrategyQuiz;
  }

  return false;
}
export const getEditorAudioStartTimeOverrideMs = ( state ) => state.ui.editorAudioStartTimeOverrideMs;
export const getEditorAnimationCompleteTimestamp = ( state ) => state.ui.editorAnimationCompleteTimestamp;
export const getEditorDesignAudioNotAllowed = ( state ) => state.ui.editorDesignAudioNotAllowed;
export const getCurrentGlobalErrorDialogType = ( state ) => state.ui.globalErrorDialogType;
export const getPaymentDialogSelectedSubscriptionDuration = ( state ) => state.ui.paymentDialogSelectedSubscriptionDuration;
export const getShowRatingWarmup = ( state ) => !!state.ui.showRatingWarmup && state.ui.showRatingWarmup;
export const getRatingWarmupSource = ( state ) => state.ui.ratingWarmupSource;

export const {
  setAudioPlayerTrack,
  setConversationYieldToDrawer,
  setConversationLastYieldToDrawerTime,
  clearAndStopAudioPlayerTrack,
  setAudioPlayerPlaying,
  setPendingDraftPostIdeaId,
  clearPendingDraftPostIdeaId,
  clearUI,
  addToMediaLibrary,
  clearMediaLibrary,
  setUpsellShown,
  setShowAddMultipleBusinessesUpsell,
  setPaymentDialogOpen,
  setPaymentDialogSelectedSubscriptionDuration,
  setPaymentConfirmationOpen,
  setProgressState,
  clearProgressState,
  setPlanLastDateClicked,
  clearPlanLastDateClicked,
  setPlanLastPostIdeaEdited,
  clearPlanLastPostIdeaEdited,
  setHasSeenPostStrategyQuiz,
  setRequestEditorReloadWithAudioTimestamp,
  clearRequestEditorReloadWithAudioTimestamp,
  setRequestEditorPauseAudioTimestamp,
  clearRequestEditorPauseAudioTimestamp,
  setRequestEditorStopAnimationTimestamp,
  setEditorAudioStartTimeOverrideMs,
  clearEditorAudioStartTimeOverrideMs,
  setEditorAnimationCompleteTimestamp,
  clearEditorAnimationCompleteTimestamp,
  setEditorDesignAudioNotAllowed,
  clearEditorDesignAudioNotAllowed,
  showGlobalErrorDialog,
  hideGlobalErrorDialog,
  setShowRatingWarmup,
  clearShowRatingWarmup
} = uiSlice.actions

export default uiSlice.reducer
