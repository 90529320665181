import {
  IOS_WARMUP_RATING_INTERACTION_COULD_BE_BETTER,
  IOS_WARMUP_RATING_INTERACTION_DISMISS,
  IOS_WARMUP_RATING_INTERACTION_GREAT,
  RATING_DIALOG_SHOWN_SOURCE_SHARE,
  RATING_DIALOG_SHOWN_SOURCE_SUBSCRIBE,
  RatingDialogShownSourceType
} from "../features/constants";
import { DateUtils } from "../features/utils/dateUtils";
import { LastIosRatingWarmup } from "../features/user/userSlice";

export class IosRatingRules
{
  static isFirstTime( lastIosRatingWarmup?: LastIosRatingWarmup )
  {
    return !this.hasSeenIosRatingWarmupDialog( lastIosRatingWarmup );
  }

  static hasSeenIosRatingWarmupDialog( lastIosRatingWarmup?: LastIosRatingWarmup )
  {
    return !!lastIosRatingWarmup;
  }

  static shouldPrompt( source: RatingDialogShownSourceType, lastIosRatingWarmup?: LastIosRatingWarmup )
  {
    if ( !this.hasSeenIosRatingWarmupDialog( lastIosRatingWarmup ) )
    {
      return true;
    }
    else if ( this.isLastIosRatingWarmupInteractionGreat( lastIosRatingWarmup ) )
    {
      return this.isAtLeastThreeMonthsAgo( lastIosRatingWarmup );
    }
    else if ( this.isLastIosRatingWarmupInteractionDismissed( lastIosRatingWarmup )
              || this.isLastIosRatingWarmupInteractionCouldBeBetter( lastIosRatingWarmup ) )
    {
      if ( lastIosRatingWarmup?.last_ios_rating_warmup_source === RATING_DIALOG_SHOWN_SOURCE_SHARE && source
           === RATING_DIALOG_SHOWN_SOURCE_SUBSCRIBE )
      {
        return true;
      }
      return this.isAtLeastOneMonthAgo( lastIosRatingWarmup );
    }

    return false;
  }

  static isAtLeastThreeMonthsAgo( lastIosRatingWarmup?: LastIosRatingWarmup )
  {
    if ( !lastIosRatingWarmup )
    {
      return false;
    }
    return DateUtils.isAtLeastNMonthsAgo( new Date( lastIosRatingWarmup.last_ios_rating_warmup_interaction_shown_date ), 3 );
  }

  static isAtLeastOneMonthAgo( lastIosRatingWarmup?: LastIosRatingWarmup )
  {
    if ( !lastIosRatingWarmup )
    {
      return false;
    }
    return DateUtils.isAtLeastNMonthsAgo( new Date( lastIosRatingWarmup.last_ios_rating_warmup_interaction_shown_date ), 1 );
  }

  static isLastIosRatingWarmupInteractionGreat( lastIosRatingWarmup?: LastIosRatingWarmup ): boolean
  {
    return lastIosRatingWarmup?.last_ios_rating_warmup_interaction_type === IOS_WARMUP_RATING_INTERACTION_GREAT;
  }

  static isLastIosRatingWarmupInteractionDismissed( lastIosRatingWarmup?: LastIosRatingWarmup ): boolean
  {
    return lastIosRatingWarmup?.last_ios_rating_warmup_interaction_type === IOS_WARMUP_RATING_INTERACTION_DISMISS;
  }

  static isLastIosRatingWarmupInteractionCouldBeBetter( lastIosRatingWarmup?: LastIosRatingWarmup ): boolean
  {
    return lastIosRatingWarmup?.last_ios_rating_warmup_interaction_type === IOS_WARMUP_RATING_INTERACTION_COULD_BE_BETTER;
  }

}
