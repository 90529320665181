import { eventTracker } from "./eventTracker";
import { amplitudeFeatureFlagsVariables } from "../features/amplitude/amplitudeFeatureFlagsVariables";
import { getRatingWarmupSource, setShowRatingWarmup } from "../features/ui/uiSlice";
import { store } from "../app/store";
import { authenticationServices } from "../services/authentication.services";
import {
  IosWarmupRatingInteraction,
  RATING_DIALOG_SHOWN_SOURCE_SETTINGS,
  RATING_DIALOG_SHOWN_SOURCE_SHARE,
  RATING_DIALOG_SHOWN_SOURCE_SUBSCRIBE,
  RatingDialogShownSourceType
} from "../features/constants";
import { IosRatingRules } from "./iosRatingRules";
import { browserUtils } from "../features/utils/browserUtils";
import { IOS_MESSAGE_TYPE_REQUEST_REVIEW, iOSMessageSender } from "../features/payment/iOSMessageSender";

export class IosRatingHelper
{
  static shouldPromptForIosRating( source: RatingDialogShownSourceType )
  {
    const isInIosApp = browserUtils.isAlkaiIOSApp();
    const appSupportRating = iOSMessageSender.doesIosClientSupportMessage( IOS_MESSAGE_TYPE_REQUEST_REVIEW )

    if ( !isInIosApp || !appSupportRating )
    {
      return false;
    }

    const lastIosRatingWarmup = store.getState().user?.last_ios_rating_warmup;
    return IosRatingRules.shouldPrompt( source, lastIosRatingWarmup );
  }

  static showRatingDialog( source: RatingDialogShownSourceType )
  {
    amplitudeFeatureFlagsVariables.promptForIosRatingFeatureEnabled().then( ( isInFeature ) =>
    {
      if ( isInFeature )
      {
        const firstTimeShown = this.isFirstTimeShown();
        eventTracker.logRatingDialogShown( source, firstTimeShown );
        store.dispatch( setShowRatingWarmup( source ) );
      }
    } );
  }

  static isFirstTimeShown()
  {
    const lastIosRatingWarmup = store.getState().user?.last_ios_rating_warmup;
    return IosRatingRules.isFirstTime( lastIosRatingWarmup );
  }

  static showRatingDialogAfterShare()
  {
    this.showRatingDialog( RATING_DIALOG_SHOWN_SOURCE_SHARE );
  }

  static showRatingDialogAfterSubscribe()
  {
    this.showRatingDialog( RATING_DIALOG_SHOWN_SOURCE_SUBSCRIBE );
  }

  static showRatingDialogAfterSettings()
  {
    this.showRatingDialog( RATING_DIALOG_SHOWN_SOURCE_SETTINGS );
  }

  static reportRatingInteraction( interaction: IosWarmupRatingInteraction )
  {
    const firstTimeShown = this.isFirstTimeShown();
    const source = getRatingWarmupSource( store.getState() );
    eventTracker.logRatingDialogClicked( source, firstTimeShown, interaction );
    authenticationServices.addIosUserRatingInteraction( interaction, source );
  }
}
