import React from 'react'
import "./message.scss";
import { MessageAPI, PlanBriefUpdatedDataAPI } from "../assistantChat/assistantChatSlice";
import { Box, Stack, Typography } from "@mui/material";
import { MessageWithAvatar } from "./messageWithAvatar";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ROUTES } from '../constants';
import LinkIcon from '@mui/icons-material/Link';
import { eventTracker } from "../../helpers/eventTracker";

export interface PlanBriefUpdatedMessageProps
{
  message: MessageAPI;
}

export function PlanBriefUpdatedMessage( props: PlanBriefUpdatedMessageProps )
{
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const data = props.message.data as PlanBriefUpdatedDataAPI;

  function handleLinkClick()
  {
    const weekStartDate = data.plan_brief.week_start_date;
    navigateWithSearchParams( ROUTES.PLAN, { date: weekStartDate } );
    eventTracker.logPlanBriefUpdatedMessageClicked( weekStartDate );
  }

  return (
    <MessageWithAvatar message={props.message} clsx={{ alki: true, diminished: true }}>
      <Box onClick={handleLinkClick} sx={{
        p: '0px 17px',
        cursor: "pointer"
      }}>
        <Stack direction="row" spacing={2}>
          <Typography variant="body1">Plan Brief Updated</Typography><LinkIcon/>
        </Stack>
      </Box>
    </MessageWithAvatar>
  )
}
