import Stack from "@mui/material/Stack";
import { DateUtils } from "../utils/dateUtils";
import { Box, Link, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { businessServices, PlanBriefAPI, PlanBriefUpdateParams } from "../../services/business.services";
import { debounce, merge } from "lodash";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ALKAI_FAQ_PLAN_BRIEF_URL, ROUTES } from "../constants";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { useDispatch } from "react-redux";
import { SxProps } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { eventTracker } from "../../helpers/eventTracker";
import { PlanBriefSourcePageType } from "../../helpers/trackingConstants";
import { parseISO } from "date-fns";

export interface PlanBriefSectionProps
{
  planBrief: PlanBriefAPI;
  weekStart: Date;
  source: PlanBriefSourcePageType;
  showSimplifiedPlanBrief?: boolean;
  sxProps?: SxProps;
}

export function PlanBriefSection( props: PlanBriefSectionProps )
{
  const AUTO_SAVE_DELAY_MS = 2500;
  const { planBrief, weekStart } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>( false);
  const [generalInstructions, setGeneralInstructions] = useState<string>( planBrief.general_instructions || "");
  const [weeklyInstructions, setWeeklyInstructions] = useState<string>( planBrief.weekly_instructions || "" );

  const planIsLocked = planBrief.locked;
  const textColor = planIsLocked ? "#9D9D9D" : "#000000";
  const availabilityTextColor = planIsLocked ? "#FF0000" : "#334FEF";
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const longWeekStartDateString = DateUtils.getLongMonthAndDayDisplayDate( weekStart )

  let hasPendingChanges = useRef<boolean>( false );
  const updatedPlanBriefParams = useRef<PlanBriefUpdateParams>( { plan_brief: planBrief } );
  const updateCutoffDate = planBrief.update_cutoff_date!! ? parseISO( planBrief.update_cutoff_date ) : undefined;
  const shortPlanExtensionDateString = DateUtils.getShortMonthAndDayDisplayDate( updateCutoffDate );
  const shortWeekStartDateString = DateUtils.getShortMonthAndDayDisplayDate( weekStart );
  const showDetailedPlanBrief = !props.showSimplifiedPlanBrief;
  let hasSentPlanBriefUpdatedEvent = useRef<boolean>( false );

  const debounceUpdatePlanBrief = useCallback( debounce( updatePlanBrief, AUTO_SAVE_DELAY_MS ), [] );

  async function updatePlanBrief()
  {
    try
    {
      setLoading( true );
      const response = await businessServices.updatePlanBrief( updatedPlanBriefParams.current.plan_brief )
      setWeeklyInstructions( response.plan_brief.weekly_instructions || "" );
      setGeneralInstructions( response.plan_brief.general_instructions );
      if ( !hasSentPlanBriefUpdatedEvent.current )
      {
        eventTracker.logPlanBriefUpdated( props.source );
        hasSentPlanBriefUpdatedEvent.current = true;
      }
    }
    catch
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem updating the Plan Brief, please refresh your browser and try again.", "top" ) ) );
    }
    finally
    {
      setLoading( false );
    }
  }

  useEffect( () =>
  {
    eventTracker.logPlanBriefShown( props.source );
  }, [] );

  function handleGeneralInstructionsChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.value;
    setGeneralInstructions( event.target.value );
    updatedPlanBriefParams.current.plan_brief.general_instructions = newValue;
    debounceUpdatePlanBrief();
  }

  function handleWeeklyInstructionsChanged( event: React.ChangeEvent<HTMLInputElement> )
  {
    hasPendingChanges.current = true;
    const newValue = event.target.value;
    setWeeklyInstructions( event.target.value );
    updatedPlanBriefParams.current.plan_brief.weekly_instructions = newValue;
    updatedPlanBriefParams.current.plan_brief.week_start_date = props.weekStart.toString();
    debounceUpdatePlanBrief();
  }

  function handlePlanSettingsClicked()
  {
    navigateWithSearchParams( ROUTES.PLAN_SETTINGS );
  }

  function getAvailabilityText()
  {
    if ( planIsLocked )
    {
      return `The week of ${shortWeekStartDateString} is no longer available to edit.`
    }

    return `Available to edit until noon ${shortPlanExtensionDateString}`;
  }

  function getPlanBriefStyle()
  {
    if ( showDetailedPlanBrief )
    {
      return { textAlign: "left", my: 10 };
    }

    return { textAlign: "left" };
  }

  return <Stack>
    {showDetailedPlanBrief && <Stack direction="row" sx={{ border: "2px solid #FEE85F", background: "#F8EECF", alignItems: "center", p: 6 }}>
      <ErrorOutlineIcon sx={{ color: "black", mr: "10px", fontSize: "20px" }}/>
      <Typography sx={{ color: "#9D9D9D", fontSize: "12px", fontWeight: 500, textAlign: "left", lineHeight: "normal" }}>
        Changes made will be reflected the next time your plan is updated. Get help with the Plan Brief <Link sx={{ color: "#9D9D9D" }}
                                                                                                              href={ALKAI_FAQ_PLAN_BRIEF_URL}
                                                                                                              target="_blank">here</Link>.
      </Typography>
    </Stack>}
    <Stack sx={merge( props.sxProps, getPlanBriefStyle() )}>
      <Typography sx={{ fontWeight: "bold", color: textColor }}>Planning Brief</Typography>
      <Typography sx={{ color: textColor }}>Review and edit the instructions Alkai will use to build your plan.</Typography>
      <Typography sx={{ color: availabilityTextColor, fontSize: "14px", fontStyle: "italic", fontWeight: 600, mt: 4 }}>
        {getAvailabilityText()}
      </Typography>

      <Typography component='div' sx={{ mt: 10, color: textColor }}><Box fontWeight='bold' display='inline'>Weekly:</Box> instructions for week
                                                                                                                          of {longWeekStartDateString}
      </Typography>
      <TextField variant="outlined"
                 multiline={true}
                 disabled={planIsLocked || loading}
                 value={weeklyInstructions}
                 onChange={handleWeeklyInstructionsChanged}
                 inputProps={{ style: { fontSize: "14px" } }}
                 minRows={5}/>

      <Typography component='div' sx={{ mt: 10, color: textColor }}><Box fontWeight='bold' display='inline'>General:</Box> long-term directions and
                                                                                                                           strategy</Typography>
      <TextField variant="outlined"
                 multiline={true}
                 disabled={planIsLocked || loading}
                 value={generalInstructions}
                 onChange={handleGeneralInstructionsChanged}
                 inputProps={{ style: { fontSize: "14px" } }}
                 minRows={5}/>
      {showDetailedPlanBrief && <Typography variant={"body2"} textAlign={"right"}>Review all <Link
        sx={{ color: "black", textDecorationColor: "black", mb: 1 }} component="button"
        alignItems={"center"}
        variant="body2"
        onClick={handlePlanSettingsClicked}>Plan Settings</Link></Typography>}
    </Stack>
  </Stack>
}
