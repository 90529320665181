import { useEffect, useState } from "react";
import { logToConsole } from "../utils/devLoggingHelper";


export const useAmplitudeFeatureFlag = ( featureFlagLoader: () => Promise<boolean>) => {
  const [value, setValue] = useState<boolean|undefined>();

  useEffect( () =>
  {
    const loadFeatureFlag = async () => {
      try
      {
        const result = await featureFlagLoader();
        setValue(result);
        logToConsole(`Executed Amplitude feature flag loader: ${featureFlagLoader.name}, result=${result}`);
      }
      catch (error) {
        logToConsole(`Failed to load feature flag ${featureFlagLoader.name}`)
      }
    }

    loadFeatureFlag();
  }, [featureFlagLoader] );

  return !!value;
}