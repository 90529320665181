import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { format, isSameMonth } from "date-fns";
import { PLAN_TAB_WEEK_HEADER_Z_INDEX } from "../constants";
import { AutoAwesome } from "@mui/icons-material";
import React from "react";
import { filter, map, size, some } from "lodash";
import { eventTracker } from "../../helpers/eventTracker";
import { businessServices, PLAN_SUGGESTION_STATUS_FAILED_TO_GENERATE_POST_IDEA, PlannerDateAPI } from "../../services/business.services";
import { errorAlert, setAlertMessage, successAlert } from "../alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { PlannerDateAPIUtils } from "../utils/plannerDateAPIUtils";
import { RootState } from "../../app/store";
import { isGuestUser } from "../user/userSlice";

interface WeekHeaderProps
{
  date: Date;
  onPreviousWeek: () => void;
  onNextWeek: () => void;
  hasNextWeeks: boolean;
  hasPreviousWeeks: boolean;
  dates: PlannerDateAPI[];
  showingPlanBrief?: boolean;
  handleUpdatePlannerData: () => void;
}

export function WeekHeader( props: WeekHeaderProps )
{
  const dispatch = useDispatch();
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const FULL_MONTH_DATE_FORMAT = "LLLL";
  const MONTH_DATE_DATE_FORMAT = "dd";
  const FULL_MONTH_AND_DATE_DATE_FORMAT = FULL_MONTH_DATE_FORMAT + " " + MONTH_DATE_DATE_FORMAT;

  function getWeekRange()
  {
    const firstDayDate = format( props.date, FULL_MONTH_AND_DATE_DATE_FORMAT );

    const lastDay = new Date( props.date );
    lastDay.setDate( props.date.getDate() + 6 );
    const lastDayMonth = format( lastDay, FULL_MONTH_DATE_FORMAT );
    const lastDayDate = format( lastDay, MONTH_DATE_DATE_FORMAT );

    if ( isSameMonth( props.date, lastDay ) )
    {
      return firstDayDate + " - " + lastDayDate;
    }
    return firstDayDate + " - " + lastDayMonth + " " + lastDayDate;
  }

  async function handleGenerateAll()
  {
    const postingPlanDatesWithoutDrafts = PlannerDateAPIUtils.futurePostingPlanDatesWithoutDrafts( props.dates );
    const postIdeaTitles = map( postingPlanDatesWithoutDrafts, ( postingPlanDate ) => postingPlanDate.post_idea_title );
    eventTracker.logGenerateAllPostsClicked( postingPlanDatesWithoutDrafts.length, postIdeaTitles );

    const datesToGenerate = map( postingPlanDatesWithoutDrafts, ( postingPlanDate ) => postingPlanDate.date );
    await businessServices.pregenerateMultiplePlanSuggestions( datesToGenerate ).then( () =>
    {
      props.handleUpdatePlannerData();
      dispatch( setAlertMessage( successAlert( getGenerateAllPostsSuccessMessage(), "top" ) ) );
    } ).catch( () =>
    {
      dispatch( setAlertMessage( errorAlert( "There was an issue generating your posts, please try again later.", "top" ) ) );
    } );
  }

  function getGenerateAllPostsSuccessMessage()
  {
    if ( isGuest )
    {
      return "Generating all posts for your current plan. Sign up to get notified when they are ready.";
    }
    return "Generating all posts for your current plan. You will be notified when they are ready.";
  }

  const isAnyPlanSuggestionActivelyBeingPregenerated = () =>
  {
    const queuedFutureDates = filter( props.dates,
      ( date ) => some( date.posting_plan_dates, postingPlanDate => (
        !!postingPlanDate.plan_suggestion?.pregenerated_at &&
        !postingPlanDate.plan_suggestion?.post_idea_id &&
        !(postingPlanDate.plan_suggestion?.status === PLAN_SUGGESTION_STATUS_FAILED_TO_GENERATE_POST_IDEA))
      ) );

    return queuedFutureDates.length > 0;
  }

  const areMultiplePlanSuggestionsAvailableForPregeneration = () =>
  {
    return size( PlannerDateAPIUtils.futurePostingPlanDatesWithoutDrafts( props.dates ) ) > 1;
  }

  const getGenerateAllTooltip = () =>
  {
    if ( isAnyPlanSuggestionActivelyBeingPregenerated() )
    {
      return "Generating posts for your plan, please wait.";
    }
    return null;
  }

  const shouldDisableGenerateAllButton = isAnyPlanSuggestionActivelyBeingPregenerated();
  const shouldShowGeneratingAllPostsText = !areMultiplePlanSuggestionsAvailableForPregeneration() && isAnyPlanSuggestionActivelyBeingPregenerated();
  const shouldShowGenerateAllButton = areMultiplePlanSuggestionsAvailableForPregeneration() && !props.showingPlanBrief;

  function getPreviousArrowHintText()
  {
    return !props.hasPreviousWeeks ? "No earlier weeks available" : "";
  }

  function getNextArrowHintText()
  {
    return !props.hasNextWeeks ? "You have reached the end of the current plan" : "";
  }

  return <Stack sx={{
    pb: 5,
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: PLAN_TAB_WEEK_HEADER_Z_INDEX
  }}>
    <Stack direction="row" spacing={2} justifyContent={"center"}>
      <Tooltip title={getPreviousArrowHintText()} enterTouchDelay={50}>
        <span>
          <Button disabled={!props.hasPreviousWeeks} sx={{ fontSize: "26px", fontWeight: "200", padding: "0", color: "black" }} variant="text"
                  onClick={() => props.onPreviousWeek()}>{'<'}</Button>
        </span>
      </Tooltip>
      <Typography variant="h5" sx={{ fontWeight: 300, pt: "7px" }}>{getWeekRange()}</Typography>
      <Tooltip title={getNextArrowHintText()} enterTouchDelay={50}>
        <span>
          <Button disabled={!props.hasNextWeeks} sx={{ fontSize: "26px", fontWeight: "200", padding: "0", color: "black" }} variant="text"
                  onClick={() => props.onNextWeek()}>{'>'}</Button>
        </span>
      </Tooltip>
    </Stack>
    {shouldShowGeneratingAllPostsText && <Box sx={{ mt: 2, mb: 2 }}>Generating posts for your plan, please wait.</Box>}
    {shouldShowGenerateAllButton && <Tooltip title={getGenerateAllTooltip()} enterTouchDelay={50}>
      <span>
    <Button variant={"contained"}
            startIcon={<AutoAwesome/>}
            sx={{ textTransform: "none", fontSize: "13px" }}
            onClick={handleGenerateAll}
            disabled={shouldDisableGenerateAllButton}>Generate all planned posts for the week</Button>
        </span>
    </Tooltip>
    }
  </Stack>
}