import { differenceInMinutes, format, formatISO, isSameDay, NearestMinutes, roundToNearestMinutes, sub } from "date-fns";

export class DateUtils
{
  static getLongFormatDate( date: Date )
  {
    return format( date, "MMMM d, yyyy" );
  }

  static formatDateToYearMonthDay( date: Date )
  {
    return format( date, "yyyy-MM-dd" );
  }

  static getLongMonthAndDayDisplayDate( date = new Date() )
  {
    return format( date, "MMMM do" );
  }

  static getShortMonthAndDayDisplayDate( date = new Date() )
  {
    return format( date, "M/d" );
  }

  static getRoundedTimeToActAsClientVersion( nearestToMinutes: NearestMinutes = 15 )
  {
    const date = roundToNearestMinutes( new Date(), { nearestTo: nearestToMinutes } );
    return date.getTime()
  }

  static getMillisecondsSinceEpoch( date = new Date() )
  {
    return date.getTime()
  }

  static getSecondsSinceEpoch( date = new Date() )
  {
    return Math.floor( this.getMillisecondsSinceEpoch( date ) / 1000 )
  }

  static getLongFormattedDisplayDate( date = new Date() )
  {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dateNumber = format( date, "d" );
    return `${day}, ${month} ${dateNumber}`;
  }

  static isToday( date: Date )
  {
    const now = new Date();

    return this.isSameCalendarDay( date, now );
  }

  static isTomorrow( date: Date )
  {
    const tomorrow = new Date();
    tomorrow.setDate( tomorrow.getDate() + 1 );
    return this.isSameCalendarDay( date, tomorrow );
  }

  static isBeforeToday( date: Date )
  {
    const today = new Date();
    return new Date( date.toDateString() ) < new Date( today.toDateString() );
  }

  static isSameCalendarDay( firstDate: Date, secondDate: Date )
  {
    return isSameDay( firstDate, secondDate );
  }

  static getMondayOfWeekForDate( date: Date ): Date
  {
    const dayOfWeek = date.getDay();
    const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust when day is Sunday
    const monday = new Date( date );
    monday.setDate( date.getDate() + difference );
    monday.setHours( 0, 0, 0, 0 );
    return monday;
  }

  //Assuming the week starts on a Monday
  static getPreviousSaturdayForDate( date: Date ): Date
  {
    const monday = this.getMondayOfWeekForDate( date );
    const saturday = sub( new Date( monday ), { days: 2 } );
    return saturday;
  }

  static toISODateString( date: Date )
  {
    return formatISO( date, { representation: 'date' } );
  }

  static toISODateTimeString( date: Date )
  {
    return formatISO( date, { representation: 'complete' } );
  }

  static isWithinFiveMinutes( laterDate: Date, earlierDate: Date )
  {
    const minutesAway = differenceInMinutes( laterDate, earlierDate );
    return minutesAway < 5;
  }

  static getDaysFromToday( futureDate: Date, currentDate: Date )
  {
    return Math.ceil( (futureDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24) );
  }

  static getNumberOfCalendarDaysAway( laterDate: Date, earlierDate: Date )
  {
    let beginningOfLaterDay = new Date( laterDate.setHours( 0, 0, 0, 0 ) );
    let beginningOfEarlierDay = new Date( earlierDate.setHours( 0, 0, 0, 0 ) );
    return this.getDaysFromToday( beginningOfLaterDay, beginningOfEarlierDay );
  }

  static isAtLeastNMonthsAgo( date: Date, months: number )
  {
    const nMonthsAgo = sub( new Date(), { months: months } )
    return date < nMonthsAgo;
  }
}
