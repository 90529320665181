import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { iOSMessageSender } from "../payment/iOSMessageSender";
import {
  IOS_WARMUP_RATING_INTERACTION_COULD_BE_BETTER,
  IOS_WARMUP_RATING_INTERACTION_DISMISS,
  IOS_WARMUP_RATING_INTERACTION_GREAT,
  ROUTES
} from "../constants";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { IosRatingHelper } from "../../helpers/iosRatingHelper";
import StarRatingImage from "../../assets/ratings/5-stars.png";
import GreatEmoji from "../../assets/ratings/greatRatingIcon.png";
import CouldBeBetterEmoji from "../../assets/ratings/couldBeBetterIcon.png";

export interface RatingWarmupDialogProps
{
  open: boolean;
  onClose: () => void;
}

const Z_INDEX_ABOVE_POST_SUCCESS_AND_SUBSCRIBE_SUCCESS = 10000;

export function RatingWarmupDialog( props: RatingWarmupDialogProps )
{
  const navigateWithSearchParams = useNavigateWithSearchParams();

  function requestIosRating()
  {
    IosRatingHelper.reportRatingInteraction( IOS_WARMUP_RATING_INTERACTION_GREAT );
    iOSMessageSender.requestReview();
    props.onClose();
  }

  function getFeedbackForm()
  {
    IosRatingHelper.reportRatingInteraction( IOS_WARMUP_RATING_INTERACTION_COULD_BE_BETTER );
    props.onClose();
    navigateWithSearchParams( ROUTES.NEW_SUPPORT_TICKET );
  }

  function closeWarmUpDialog()
  {
    IosRatingHelper.reportRatingInteraction( IOS_WARMUP_RATING_INTERACTION_DISMISS );
    props.onClose();
  }

  return <Dialog
    sx={{
      '& .MuiDialog-paper': {
        width: '90%',
        maxHeight: '100vh',
        borderRadius: "20px",
        background: "linear-gradient(62deg, rgba(66, 53, 147, 0.10) 29.86%, rgba(151, 69, 122, 0.10) 55.11%, rgba(250, 88, 94, 0.10) 84.33%)",
        backgroundColor: "white",
        overflowY: "visible",
      },
      textAlign: "center",
      zIndex: Z_INDEX_ABOVE_POST_SUCCESS_AND_SUBSCRIBE_SUCCESS
    }}
    open={props.open}
  >
    <IconButton
      aria-label="close"
      size="small"
      onClick={closeWarmUpDialog}
      sx={{ flex: "0 0 auto", position: "absolute", right: 8, top: 8, color: "black" }}>
      <CloseIcon fontSize="inherit"/>
    </IconButton>
    <Box component="img" src={StarRatingImage} sx={{ width: "75%", alignSelf: "center", marginTop: "-70px" }}/>
    <Typography sx={{
      fontWeight: 900,
      fontSize: "20px",
      lineHeight: "38px",
      pt: "12px",
      pb: "9px",
      backgroundImage: "-webkit-linear-gradient(82deg, #423593 31.67%, #97457A 49.15%, #FA585E 69.38%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent"
    }}>
      HOW DID ALKAI DO?
    </Typography>
    <Stack sx={{ textAlign: "left", px: 20, pb: 20 }}>
      <Button startIcon={<Box component="img" src={GreatEmoji} sx={{ height: "26px" }}/>}
              variant="contained"
              color="alternate"
              sx={{
                mb: "12px",
                borderRadius: "20px",
                justifyContent: "flex-start",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.50)"
              }}
              onClick={requestIosRating}>
        <Typography sx={{ width: "100%", ml: "-26px", fontWeight: 700 }}>GREAT!</Typography>
      </Button>
      <Button startIcon={<Box component="img" src={CouldBeBetterEmoji} sx={{ height: "26px" }}/>}
              variant="contained"
              color="alternate"
              sx={{
                borderRadius: "20px",
                justifyContent: "flex-start",
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.50)",
              }}
              onClick={getFeedbackForm}>
        <Typography sx={{ width: "100%", ml: "-26px", fontWeight: 700 }}>COULD BE BETTER</Typography>
      </Button>
    </Stack>
  </Dialog>
}
