import { logToConsole, logToConsoleError } from "../utils/devLoggingHelper";
import { getUserSlug, isGuestUser, isSubscribedUser } from "../user/userSlice";
import { store } from "../../app/store";
import { authenticationServices } from "../../services/authentication.services";
import { iosSubscriberServices } from "../../services/ios_subscription.services";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { browserUtils } from "../utils/browserUtils";
import { setPaymentConfirmationOpen } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { isEmpty } from "lodash";
import { CREATE_IOS_SUBSCRIPTION_FAILURE_CONTEXT } from "../../helpers/trackingConstants";
import { IosRatingHelper } from "../../helpers/iosRatingHelper";
import { RATING_DIALOG_SHOWN_SOURCE_SHARE, RATING_DIALOG_SHOWN_SOURCE_SUBSCRIBE } from "../constants";

export interface IosAppVersionInfo
{
  appVersion: string;
  buildVersion: string;
}

export interface TrackingPermissionsInfo
{
  trackingAuthorizationStatus: number;
}

export const iOSMessageSender = {
  startSubscription,
  login,
  logout,
  startDownload,
  startManageSubscription,
  setupCommandsAppCanInvoke,
  connectSocialNetwork,
  clearFacebookCacheData,
  requestReview,
  fetchIosAppVersionInfo,
  requestTrackingPermissions,
  doesIosClientSupportMessage,
}

declare type IosMessageType = typeof IOS_MESSAGE_TYPE_SUBSCRIBE |
  typeof IOS_MESSAGE_TYPE_DOWNLOAD |
  typeof IOS_MESSAGE_TYPE_MANAGE_SUBSCRIPTION |
  typeof IOS_MESSAGE_TYPE_LOGIN |
  typeof IOS_MESSAGE_TYPE_LOGOUT |
  typeof IOS_MESSAGE_TYPE_CONNECT_SOCIAL_NETWORK |
  typeof IOS_MESSAGE_TYPE_CLEAR_FACEBOOK_CACHE_DATA |
  typeof IOS_MESSAGE_TYPE_FETCH_IOS_APP_VERSION_INFO |
  typeof IOS_MESSAGE_TYPE_REQUEST_TRACKING_PERMISSIONS |
  typeof IOS_MESSAGE_TYPE_REQUEST_REVIEW;

const IOS_MESSAGE_TYPE_SUBSCRIBE = 'subscribe';
const IOS_MESSAGE_TYPE_DOWNLOAD = 'download';
const IOS_MESSAGE_TYPE_MANAGE_SUBSCRIPTION = 'manageSubscription';
const IOS_MESSAGE_TYPE_LOGIN = 'login';
const IOS_MESSAGE_TYPE_LOGOUT = 'logout';
const IOS_MESSAGE_TYPE_CONNECT_SOCIAL_NETWORK = 'connectSocialNetwork';
const IOS_MESSAGE_TYPE_CLEAR_FACEBOOK_CACHE_DATA = 'clearFacebookCacheData';
const IOS_MESSAGE_TYPE_FETCH_IOS_APP_VERSION_INFO = 'fetchIosAppVersionInfo';
const IOS_MESSAGE_TYPE_REQUEST_TRACKING_PERMISSIONS = 'requestTrackingPermissions';
export const IOS_MESSAGE_TYPE_REQUEST_REVIEW = 'requestReview';

function setupCommandsAppCanInvoke()
{
  if ( window.webkit )
  {
    window.subscribeComplete = async ( iosClientSuccessFlag: boolean, entitlementLookupKey: string ) =>
    {
      store.dispatch( setApplicationLoadingState( true ) );

      if ( iosClientSuccessFlag && !isEmpty( entitlementLookupKey ) )
      {
        try
        {
          const payload = await iosSubscriberServices.createSubscriber( entitlementLookupKey );
          const iosServerSuccessFlag = payload.success;
          await authenticationServices.refreshUser();
          const storeState = store.getState();
          const userIsSubscribed = isSubscribedUser( storeState );
          if ( userIsSubscribed )
          {
            store.dispatch( setPaymentConfirmationOpen( true ) );
            if ( IosRatingHelper.shouldPromptForIosRating( RATING_DIALOG_SHOWN_SOURCE_SUBSCRIBE ) )
            {
              IosRatingHelper.showRatingDialogAfterSubscribe();
            }
          }

          eventTracker.logIosCheckoutComplete( iosClientSuccessFlag );

          if ( !iosServerSuccessFlag )
          {
            store.dispatch(
              setAlertMessage(
                errorAlert( "Unable to complete subscription. Please click try again or contact support if the problem persists." ) ) );
            eventTracker.logIosSubscribeFailed( CREATE_IOS_SUBSCRIPTION_FAILURE_CONTEXT, "Server was unable to create iOS subscription." );
          }

          logToConsole( "subscribeComplete message received with iOS client successFlag:", iosClientSuccessFlag,
            "and server successFlag:", iosServerSuccessFlag );
        }
        catch (error)
        {
          logToConsoleError( "Error occurred while subscribing and refreshing user", error );
          eventTracker.logIosSubscribeFailed( CREATE_IOS_SUBSCRIPTION_FAILURE_CONTEXT, "Server threw an error during iOS subscription creation." );
        }

        store.dispatch( setApplicationLoadingState( false ) );
      }
      else
      {
        eventTracker.logIosCheckoutCanceled();
        store.dispatch( setApplicationLoadingState( false ) );
      }
    }

    window.downloadComplete = ( successFlag: boolean ) =>
    {
      logToConsole( "downloadComplete message received with successFlag", successFlag );
      if ( IosRatingHelper.shouldPromptForIosRating( RATING_DIALOG_SHOWN_SOURCE_SHARE ) )
      {
        IosRatingHelper.showRatingDialogAfterShare();
      }
    }

    window.connectSocialNetworkComplete = ( successFlag: boolean, extraData?: object ) =>
    {
      logToConsole( "connectSocialNetworkComplete message received with successFlag", successFlag, "extra data?", extraData );
      if ( window.iosConnectSocialAccountsListener )
      {
        window.iosConnectSocialAccountsListener( successFlag, extraData );
        window.iosConnectSocialAccountsListener = undefined;
      }
    }

    window.subscribeError = ( data: object ) =>
    {
      const errorContext = data["errorContext"];
      const errorMessage = data["errorMessage"];
      const errorType = data["errorType"];
      const errorCode = data["errorCode"];

      logToConsoleError( "subscribeError message received with error", errorContext, errorMessage, errorType, errorCode );

      eventTracker.logIosSubscribeFailed( errorContext, errorMessage, errorType, errorCode );
    }
  }
}

function startSubscription()
{
  sendMessageIfSupported( IOS_MESSAGE_TYPE_SUBSCRIBE, {
    "message": "startSubscription",
    "userSlug": getUserSlug( store.getState() ),
  } );
}

function startDownload( outputMediaUrl: String, outputFileName: String )
{

  sendMessageIfSupported( IOS_MESSAGE_TYPE_DOWNLOAD, {
    "message": "startDownload",
    "outputFileName": outputFileName,
    "outputMediaUrl": outputMediaUrl,
  } );
}

function startManageSubscription()
{
  sendMessageIfSupported( IOS_MESSAGE_TYPE_MANAGE_SUBSCRIPTION, {
    "message": "startManageSubscription",
    "userSlug": getUserSlug( store.getState() ),
  } );
}

function login()
{
  const storeState = store.getState();
  sendMessageIfSupported( IOS_MESSAGE_TYPE_LOGIN, {
    "userSlug": getUserSlug( storeState ),
    "isGuestUser": isGuestUser( storeState )
  } );
}

function logout()
{
  sendMessageIfSupported( IOS_MESSAGE_TYPE_LOGOUT, {
    "userSlug": getUserSlug( store.getState() )
  } );
}

function connectSocialNetwork( url: string )
{
  sendMessageIfSupported( IOS_MESSAGE_TYPE_CONNECT_SOCIAL_NETWORK, {
    "userSlug": getUserSlug( store.getState() ),
    "url": url
  } );
}

function clearFacebookCacheData()
{
  sendMessageIfSupported( IOS_MESSAGE_TYPE_CLEAR_FACEBOOK_CACHE_DATA, {
    "userSlug": getUserSlug( store.getState() )
  } );
}

function requestReview()
{
  return sendMessageWithReplyHandler( IOS_MESSAGE_TYPE_REQUEST_REVIEW, {} );
}

function requestTrackingPermissions(): Promise<TrackingPermissionsInfo>
{
  return sendMessageWithReplyHandler( IOS_MESSAGE_TYPE_REQUEST_TRACKING_PERMISSIONS, {} );
}

function fetchIosAppVersionInfo(): Promise<IosAppVersionInfo>
{
  let payload = {
    "message": "fetchIosAppVersionInfo"
  };
  return sendMessageWithReplyHandler( IOS_MESSAGE_TYPE_FETCH_IOS_APP_VERSION_INFO, payload );
}

function sendMessageIfSupported( messageType: IosMessageType, payload: any )
{
  if ( !browserUtils.isAlkaiIOSApp() )
  {
    return false;
  }
  // @ts-ignore
  const webkit = window.webkit;
  if ( doesIosClientSupportMessage( messageType ) )
  {
    logToConsole( `Sending iOS message "${messageType}" with payload ${JSON.stringify( payload || {} )}` )
    webkit.messageHandlers[messageType].postMessage( payload )
    return true;
  }
  else
  {
    logToConsoleError( `iOS message handler "${messageType}" is not supported` );
  }
  return false;
}

function sendMessageWithReplyHandler( messageName: IosMessageType, payload: {} ): Promise<any>
{
  if ( !browserUtils.isAlkaiIOSApp() )
  {
    return Promise.reject( "not in iOS app" );
  }
  // @ts-ignore
  const webkit = window.webkit;
  if ( doesIosClientSupportMessage( messageName ) )
  {
    const messageId = `${messageName}_${Date.now()}`;

    const messagePayload = {
      ...payload,
      replyHandler: messageId
    };

    logToConsole( `Sending iOS message "${messageName}" with payload ${JSON.stringify( messagePayload || {} )} with reply handler ${messageId}` );
    return webkit.messageHandlers[messageName].postMessage( messagePayload );
  }
  else
  {
    let errorMessage = `iOS message with reply handler "${messageName}" is not supported`;
    logToConsoleError( errorMessage );
    return Promise.reject( errorMessage );
  }
}

function doesIosClientSupportMessage( messageType: IosMessageType ): boolean
{
  // @ts-ignore
  const webkit = window.webkit;
  return webkit && webkit.messageHandlers && webkit.messageHandlers[messageType];
}
