import { Box, Button, Divider, Link, Stack, Typography } from "@mui/material";
import { SignOutButton } from "../registration/signOutButton";
import React, { ReactNode, useEffect, useState } from "react";
import { NewBusinessButton } from "../buttons/newBusinessButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isDevModeEnabled } from "../featureFlags/featureFlagsSlice";
import { UserSettingsBusinessDetails } from "./userSettingsBusinessDetails";
import AlertBanner from "../alert/alertBanner";
import {
  getIsInternalUser,
  hasIosSubscription,
  hasMultipleBusinesses,
  hasStripeSubscription,
  isGuestUser,
  isMonthlySubscriber,
  isSubscribedUser,
  isUserLoggedIn,
  isUserVerificationRequired,
  wasSubscribedUser
} from "../user/userSlice";
import {
  ALKAI_BLOG_URL,
  ALKAI_FAQ_URL,
  ALKAI_GET_THE_APP_APPSTORE_URL,
  ALKAI_GET_THE_APP_WEBSITE_URL,
  CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS,
  FACEBOOK_ALKAI_URL,
  INSTAGRAM_ALKAI_URL,
  PRIVACY_POLICY_URL,
  ROUTE_PARAM,
  ROUTE_SEO,
  ROUTES,
  SURVEY_MONKEY_JUNE_2024_SURVEY_ID,
  TERMS_OF_USE_URL
} from "../constants";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { authenticationServices } from "../../services/authentication.services";
import {
  PORTAL_TYPE_MANAGE_SUBSCRIPTION,
  ScheduledDowngradeAttributes,
  stripeServices,
  UNABLE_TO_OPEN_MANAGE_SUBSCRIPTION_PORTAL
} from "../../services/stripe.services";
import { setShowAddMultipleBusinessesUpsell, setUpsellShown } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { UPSELL_SOURCE_USER_SETTINGS } from "../../helpers/trackingConstants";
import { ALKAI_VERSION } from "../../constants";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { getBusinessId, hasBrandStyle, hasCompletedFirstRun } from "../business/businessSlice";
import { Helmet } from "react-helmet";
import { SurveyLink } from "./surveyLink";
import { PostingStrategyQuizFullScreenDialog } from "../postingStrategyQuiz/postingStrategyQuizFullScreenDialog";
import { businessServices } from "../../services/business.services";
import { UrlUtils } from "../utils/urlUtils";
import { useSearchParams } from "react-router-dom";
import useRemoveSearchParamByKey from "../hooks/useRemoveSearchParamByKey";
import { setApplicationLoadingState } from "../loadingIndicator/loadingSlice";
import { errorAlert, infoAlert, setAlertMessage } from "../alert/alertSlice";
import { UserSettingsRow } from "./userSettingsRow";
import StoreOutlined from "@mui/icons-material/StoreOutlined";
import { AccountCircleOutlined, DoNotDisturbOnOutlined, SellOutlined } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { ConfirmDialog } from "../ui/confirmDialog";
import { DateUtils } from "../utils/dateUtils";
import { SocialMediaAccountsSummary } from "./socialMediaAccountsSummary";
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import { AmplitudeFeatureFlagVariablesLogView } from "../amplitude/amplitudeFeatureFlagVariablesLogView";
import { browserUtils } from "../utils/browserUtils";
import { IosAppVersionInfo, iOSMessageSender } from "../payment/iOSMessageSender";
import { logToConsoleError } from "../utils/devLoggingHelper";
import { isEmpty } from "lodash";
import { IosRatingHelper } from "../../helpers/iosRatingHelper";
import { UpgradeUserSettingsRow } from "./upgradeUserSettingsRow";

export function UserSettings()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const devModeEnabled = useSelector( ( state: RootState ) => isDevModeEnabled( state ) );
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const isVerificationRequired = useSelector( ( state: RootState ) => isUserVerificationRequired( state ) );
  const [showCreateLogin, setShowCreateLogin] = useState<boolean>( isVerificationRequired );
  const [showPostingStrategyQuiz, setShowPostingStrategyQuiz] = useState<boolean>( false );
  const wasSubscribedToStripe = useSelector( ( state: RootState ) => wasSubscribedUser( state ) );
  const shouldShowCheckoutButton = useSelector( ( state: RootState ) => !isSubscribedUser( state ) && !wasSubscribedToStripe );
  const isSubscribed = useSelector( ( state: RootState ) => isSubscribedUser( state ) );
  const shouldShowCustomerPortal = isSubscribed || wasSubscribedToStripe;
  const shouldShowBrandStyle = useSelector( ( state: RootState ) => hasBrandStyle( state ) );
  const businessId = useSelector( ( state: RootState ) => getBusinessId( state ) ) || "";

  const [searchParams,] = useSearchParams();
  const navigatedWithUpsellParam = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.SETTINGS_TAB_UPSELL ) );
  const returnFromStripe = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.RETURN_FROM_STRIPE ) );
  const upgradeToYearlyCompleted = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.UPGRADE_TO_YEARLY_COMPLETED ) );
  const hasFinishedFirstRun = useSelector( ( state: RootState ) => hasCompletedFirstRun( state ) );
  const hasMoreThanOneBusiness = useSelector( ( state: RootState ) => hasMultipleBusinesses( state ) );
  const isInternalUser = useSelector( ( state: RootState ) => getIsInternalUser( state ) );
  const removeSearchParamByKey = useRemoveSearchParamByKey();
  const [scheduledDowngrade, setScheduledDowngrade] = useState<ScheduledDowngradeAttributes>();
  const [showRemoveBusinessWarmup, setShowRemoveBusinessWarmup] = useState<boolean>( false );
  const [showAcknowledgeScheduledDowngradeDialog, setShowAcknowledgeScheduledDowngradeDialog] = useState<boolean>( false );
  const [removeBusinessWarmupDialogContent, setRemoveBusinessWarmupDialogContent] = useState<ReactNode>();
  const [removeBusinessSuccessDialogContent, setRemoveBusinessSuccessDialogContent] = useState<ReactNode>();
  const [showRemoveBusinessSuccessDialog, setShowRemoveBusinessSuccessDialog] = useState<boolean>( false );
  const [showBillingPortalWarningDialog, setShowBillingPortalWarningDialog] = useState<boolean>( false );
  const [showSubscriptionManagementWarningDialog, setShowSubscriptionManagementWarningDialog] = useState<boolean>( false );
  const userHasIosSubscription = useSelector( ( state: RootState ) => hasIosSubscription( state ) );
  const userHasStripeSubscription = useSelector( ( state: RootState ) => hasStripeSubscription( state ) );
  const cannotRemoveMoreBusinesses = scheduledDowngrade && scheduledDowngrade.new_quantity <= 1;
  const isInAlkaiIOSApp = browserUtils.isAlkaiIOSApp();
  const isInIosProgressiveApp = browserUtils.isIosProgressiveWebApp();
  const isSubscribedViaIos = useSelector( ( state: RootState ) => hasIosSubscription( state ) );
  const shouldShowAddBusinessButton = isSubscribed && !isInAlkaiIOSApp && !isSubscribedViaIos;
  const shouldShowRemoveBusinessButton = hasMoreThanOneBusiness && !cannotRemoveMoreBusinesses && !isInAlkaiIOSApp;
  const [iosAppVersionInfo, setAppIosVersionInfo] = useState<IosAppVersionInfo>();
  const monthlySubscriber = useSelector( ( state: RootState ) => isMonthlySubscriber( state ) );
  const shouldShowSettingUpgradeYearlyOption = monthlySubscriber
                                               && userHasStripeSubscription
                                               && !scheduledDowngrade;

  useEffect( () =>
  {
    authenticationServices.refreshUser().then( ( data ) =>
    {
      handleUpsellDeeplink();
      handleReturnFromStripeCustomerPortal();
    } );

    stripeServices.activeSubscriptionInfo().then( ( subscriptionInfo ) =>
    {
      if ( subscriptionInfo.active_subscription?.scheduled_downgrade )
      {
        setScheduledDowngrade( subscriptionInfo.active_subscription.scheduled_downgrade )
      }
    } );

    if ( isInAlkaiIOSApp && isEmpty( iosAppVersionInfo ) )
    {
      iOSMessageSender.fetchIosAppVersionInfo().then( ( versionInfo ) =>
      {
        setAppIosVersionInfo( versionInfo );
      } );
    }
  }, [] );


  function handleReturnFromStripeCustomerPortal()
  {
    if ( returnFromStripe )
    {
      removeSearchParamByKey( ROUTE_PARAM.RETURN_FROM_STRIPE );
      dispatch( setApplicationLoadingState( false ) );
    }

    if ( upgradeToYearlyCompleted )
    {
      removeSearchParamByKey( ROUTE_PARAM.UPGRADE_TO_YEARLY_COMPLETED );
      eventTracker.logUpgradeToYearlyCompleted();
    }
  }

  function handleUpsellDeeplink()
  {
    if ( navigatedWithUpsellParam )
    {
      removeSearchParamByKey( ROUTE_PARAM.SETTINGS_TAB_UPSELL );

      if ( !isSubscribed )
      {
        dispatch( setUpsellShown( true ) );
      }
    }
  }

  function handleCreateLoginClicked()
  {
    setShowCreateLogin( !showCreateLogin );
  }

  function handleDialogOnClose()
  {
    setShowCreateLogin( false );
  }

  function handleCheckoutClicked()
  {
    if ( hasFinishedFirstRun )
    {
      dispatch( setUpsellShown( true ) )
      eventTracker.logUpsellShown( UPSELL_SOURCE_USER_SETTINGS );
    }
    else
    {
      dispatch( setAlertMessage( infoAlert( "Please complete your first post before subscribing." ) ) );
    }
  }

  function handleUpgradeYearlyClicked()
  {
    eventTracker.logUpgradeToYearlyButtonClicked();
    dispatch( setUpsellShown( true ) )
    eventTracker.logUpgradeToYearlyUpsellShown( UPSELL_SOURCE_USER_SETTINGS );
  }

  async function handleClickOnCustomerPortal()
  {
    eventTracker.logManageSubscriptionClicked();

    if ( userHasStripeSubscription || wasSubscribedToStripe )
    {
      dispatch( setApplicationLoadingState( true ) );
      const subscriptionInfo = await stripeServices.activeSubscriptionInfo();
      if ( subscriptionInfo.active_subscription?.scheduled_downgrade )
      {
        setScheduledDowngrade( subscriptionInfo.active_subscription.scheduled_downgrade );
        setShowBillingPortalWarningDialog( true );
        return;
      }

      stripeServices.customerPortalManageSubscription().catch( ( error ) =>
      {
        eventTracker.logStripeCustomerPortalFailed( error, PORTAL_TYPE_MANAGE_SUBSCRIPTION );
        dispatch( setAlertMessage( errorAlert( UNABLE_TO_OPEN_MANAGE_SUBSCRIPTION_PORTAL ) ) );
        dispatch( setApplicationLoadingState( false ) );
      } );
    }
    else if ( userHasIosSubscription && !isInAlkaiIOSApp )
    {
      setShowSubscriptionManagementWarningDialog( true );
    }
    else if ( userHasIosSubscription && isInAlkaiIOSApp )
    {
      iOSMessageSender.startManageSubscription();
    }
    else
    {
      logToConsoleError( "triggering manage subscription with unsupported subscription platform" );
    }
  }

  async function handleAddMultipleBusinesses()
  {
    eventTracker.logAddBusinessButtonClicked();
    dispatch( setApplicationLoadingState( true ) );
    const response = await businessServices.getAddBusinessesEligibility();
    if ( response.can_add_more_businesses && response.scheduled_downgrade )
    {
      setShowAcknowledgeScheduledDowngradeDialog( true );
    }
    else
    {
      if ( response.can_add_more_businesses && response.upgrade_required )
      {
        dispatch( setShowAddMultipleBusinessesUpsell( true ) );
      }
      else if ( response.can_add_more_businesses && !response.upgrade_required )
      {
        navigateWithSearchParams( ROUTES.ADD_BUSINESS_PURCHASE_COMPLETED );
      }
      else
      {
        dispatch( setAlertMessage( errorAlert( "You cannot add another business" ) ) );
      }
    }
    dispatch( setApplicationLoadingState( false ) );
  }

  function handleRemoveBusinessClicked()
  {
    dispatch( setApplicationLoadingState( true ) );
    eventTracker.logRemoveBusinessButtonClicked();
    getRemoveBusinessWarmupDialogContent().then( () =>
    {
      eventTracker.logRemoveBusinessWarmupShown();
      setShowRemoveBusinessWarmup( true );
    } ).finally( () =>
    {
      dispatch( setApplicationLoadingState( false ) );
    } );
  }

  function handleConfirmRemoveBusiness()
  {
    eventTracker.logRemoveBusinessConfirmClicked();
    stripeServices.removeBusiness().then( ( scheduledDowngradeInfo ) =>
    {
      setScheduledDowngrade( scheduledDowngradeInfo.scheduled_downgrade );
      const dateString = DateUtils.getLongFormatDate( new Date( scheduledDowngradeInfo.scheduled_downgrade.next_phase_start_date ) );
      setRemoveBusinessSuccessDialogContent(
        <Typography sx={{ px: 10 }}>
          When the business is removed on <b>{dateString}</b>, you’ll be able to select the business(s) you want to stay active on your subscription.
        </Typography>
      )
      setShowRemoveBusinessSuccessDialog( true );
    } ).catch( ( error ) =>
    {
      dispatch( setAlertMessage( errorAlert( "We are having a problem processing the request. Please try again." ) ) );
    } ).finally( () =>
    {
      setShowRemoveBusinessWarmup( false );
    } )
  }

  function handleAcknowledgeRemoveBusinessSuccessDialog()
  {
    setShowRemoveBusinessSuccessDialog( false );
  }

  function handleCancelRemoveBusiness()
  {
    eventTracker.logRemoveBusinessCancelClicked();
    setShowRemoveBusinessWarmup( false );
  }

  function handleContactSupportClicked()
  {
    handleCloseAcknowledgeScheduledDowngradeDialog();
    navigateWithSearchParams( ROUTES.NEW_SUPPORT_TICKET );
  }

  function handleCloseAcknowledgeScheduledDowngradeDialog()
  {
    setShowAcknowledgeScheduledDowngradeDialog( false );
  }

  function handleBillingPortalWarningDialogContinue()
  {
    handleCloseBillingPortalWarningDialog();
    stripeServices.customerPortalManageSubscription().catch( ( error ) =>
    {
      eventTracker.logStripeCustomerPortalFailed( error, PORTAL_TYPE_MANAGE_SUBSCRIPTION );
      dispatch( setAlertMessage( errorAlert( UNABLE_TO_OPEN_MANAGE_SUBSCRIPTION_PORTAL ) ) );
    } );
  }

  function handleCloseBillingPortalWarningDialog()
  {
    dispatch( setApplicationLoadingState( false ) );
    setShowBillingPortalWarningDialog( false );
  }

  function handleCloseSubscriptionManagementWarningDialog()
  {
    dispatch( setApplicationLoadingState( false ) );
    setShowSubscriptionManagementWarningDialog( false );
  }

  function handleNavigateToBranding()
  {
    eventTracker.logStyleAndBrandingClicked( businessId );
    navigateWithSearchParams( ROUTES.BRANDING );
  }

  function handleShowSocialMediaAccountManagement()
  {
    navigateWithSearchParams( ROUTES.SOCIAL_MEDIA_ACCOUNTS );
  }

  function handleSubmitAHelpTicket()
  {
    eventTracker.logSubmitAHelpTicketClicked();
    navigateWithSearchParams( ROUTES.NEW_SUPPORT_TICKET );
  }

  function openAccountSettings()
  {
    navigateWithSearchParams( ROUTES.ACCOUNT_SETTINGS );
  }

  function handleShowPostingStrategyQuiz()
  {
    setShowPostingStrategyQuiz( true );
  }

  function closePostingStrategyQuiz()
  {
    setShowPostingStrategyQuiz( false );
  }

  function startIosSubscription()
  {
    iOSMessageSender.startSubscription();
  }

  async function getRemoveBusinessWarmupDialogContent()
  {
    const subscriptionInfo = await stripeServices.activeSubscriptionInfo();
    setScheduledDowngrade( subscriptionInfo.active_subscription?.scheduled_downgrade );
    const dateString = DateUtils.getLongFormatDate( new Date( subscriptionInfo.active_subscription?.current_period_end_date ) );

    setRemoveBusinessWarmupDialogContent(
      <Typography sx={{ px: 10 }}>
        <p>By proceeding, one business will be removed from your subscription at the end of the current subscription period
           on <b>{dateString}</b>.</p>
        <p>You’ll continue to have access to the business through the remainder of the current subscription period.</p>
      </Typography>
    )
  }

  function getFormattedMessageForContactSupportDialog(): React.ReactNode
  {
    return <Typography sx={{ px: 10 }}>
      <p>You have a business scheduled to be removed at the end of the current subscription period.</p>
      <p>Please contact support so we can best assist you with adding a new business or canceling the scheduled business removal.</p>
    </Typography>
  }

  function getFormattedMessageForBillingPortalWarningDialog(): React.ReactNode
  {
    return <Typography sx={{ px: 10 }}>
      <p>You have a business scheduled to be removed at the end of the current subscription period. During this time the billing portal will have
         limited functionality.</p>
      <p>Please <Link href={ROUTES.NEW_SUPPORT_TICKET}>contact support</Link> if you need additional assistance during
         this time.</p>
    </Typography>
  }

  function getFormattedMessageForSubscriptionManagementWarningDialog(): React.ReactNode
  {
    return <Typography sx={{ px: 10 }}>
      <p>Your subscription is billed through Apple's App Store. Manage your subscription on your Apple device with our app or in Settings &gt; Apple
         Account &gt; Subscriptions</p>
    </Typography>
  }

  function handlePlanSettingsClicked()
  {
    navigateWithSearchParams( ROUTES.PLAN_SETTINGS );
  }

  function getAppLink()
  {
    if ( isInIosProgressiveApp )
    {
      return ALKAI_GET_THE_APP_APPSTORE_URL;
    }
    else
    {

      return ALKAI_GET_THE_APP_WEBSITE_URL;
    }
  }

  function showRatingPrompt()
  {
    IosRatingHelper.showRatingDialogAfterSettings();
  }

  return <Stack spacing={5} sx={{ textAlign: "left", px: 5, py: 10 }}>
    <Helmet>
      <title>{ROUTE_SEO.USER_SETTINGS.title}</title>
      <meta name="description" content={ROUTE_SEO.USER_SETTINGS.description}/>
    </Helmet>

    {isGuest && <Button variant="outlined" onClick={handleCreateLoginClicked}>Create account</Button>}
    {
      userIsLoggedIn && hasFinishedFirstRun && <UserSettingsBusinessDetails/>
    }

    {
      shouldShowBrandStyle && <UserSettingsRow icon={<StoreOutlined/>}
                                               label="Brand kit"
                                               onClick={handleNavigateToBranding}/>
    }
    <SocialMediaAccountsSummary onClick={handleShowSocialMediaAccountManagement}/>
    <UserSettingsRow icon={<EditCalendarOutlinedIcon/>}
                     label="Plan settings"
                     onClick={handlePlanSettingsClicked}/>
    <Divider/>
    <SurveyLink variant="subtitle1" surveyId={SURVEY_MONKEY_JUNE_2024_SURVEY_ID}/>
    <Divider/>
    {
      shouldShowCheckoutButton && <UserSettingsRow icon={<SellOutlined/>}
                                                   label="Subscribe now"
                                                   onClick={handleCheckoutClicked}/>
    }
    {shouldShowSettingUpgradeYearlyOption && <UpgradeUserSettingsRow onClick={handleUpgradeYearlyClicked}/>}
    {
      shouldShowCustomerPortal && <Box>
                                 <UserSettingsRow icon={<ManageAccountsOutlinedIcon/>}
                                                  label="Manage subscription"
                                                  onClick={handleClickOnCustomerPortal}/>
                                 {wasSubscribedToStripe && !isSubscribed && <Typography sx={{ pl: "35px", textAlign: "left" }} variant="body2">Your subscription is no longer
                                                                                                                                               active.<br/> Please
                                                                                                                                               renew to continue using
                                                                                                                                               Alkai.</Typography>}
                               </Box>
    }


    {
      shouldShowAddBusinessButton &&
      <UserSettingsRow icon={<AddBusinessOutlinedIcon/>}
                       label="Add a business"
                       onClick={handleAddMultipleBusinesses}/>
    }
    {
      shouldShowRemoveBusinessButton &&
      <UserSettingsRow icon={<DoNotDisturbOnOutlined/>}
                       label="Remove business"
                       onClick={handleRemoveBusinessClicked}/>
    }
    <Typography variant="subtitle1">For Alkai tips & tricks:</Typography>
    <UserSettingsRow icon={<InstagramIcon sx={{ color: "black" }}/>}
                     label="Follow us on Instagram"
                     href={INSTAGRAM_ALKAI_URL}/>
    <UserSettingsRow icon={<FacebookIcon sx={{ color: "black" }}/>}
                     label="Check out our Facebook Page"
                     href={FACEBOOK_ALKAI_URL}/>
    <UserSettingsRow icon={<BookOutlinedIcon/>}
                     label="Read our blog"
                     href={ALKAI_BLOG_URL}/>
    <Divider/>
    {!isInAlkaiIOSApp && <UserSettingsRow icon={<ImportantDevicesOutlinedIcon/>}
                                          label="Get the app"
                                          href={getAppLink()}/>}
    <UserSettingsRow icon={<HelpOutlineOutlinedIcon/>}
                     label="Help center"
                     href={ALKAI_FAQ_URL}/>
    <UserSettingsRow icon={<ChatOutlinedIcon/>}
                     label="Submit a help ticket"
                     onClick={handleSubmitAHelpTicket}/>
    <Divider/>
    <UserSettingsRow icon={<AccountCircleOutlined/>}
                     label="Account settings"
                     onClick={openAccountSettings}/>
    <Divider/>
    {userIsLoggedIn && <SignOutButton/>}

    <Stack direction="row"
           justifyContent="space-around">
      <Link sx={{ color: "black" }} href={TERMS_OF_USE_URL} rel="noopener" target="_blank">Terms of Use</Link>
      <Link sx={{ color: "black" }} href={PRIVACY_POLICY_URL} rel="noopener" target="_blank">Privacy Policy</Link>
    </Stack>
    <Divider/>
    {!!iosAppVersionInfo && <Typography variant={"subtitle1"} sx={{ textAlign: "center" }}>iOS App
                                                                                           Version: {iosAppVersionInfo.appVersion} ({iosAppVersionInfo.buildVersion})</Typography>}
    {
      devModeEnabled && <Stack spacing={10} sx={{ textAlign: "left", background: "rgba(251, 231, 239, 0.8)" }}>
                       <Typography variant="h5">Dev mode section</Typography>
                       {isInAlkaiIOSApp && <Typography variant="subtitle1" onClick={showRatingPrompt}>Rate us</Typography>}
                       {isInAlkaiIOSApp && <Button onClick={startIosSubscription}>Start iOS subscription</Button>}

                       <UserSettingsRow icon={<HandymanOutlinedIcon/>}
                                        label="Show posting strategy quiz"
                                        onClick={handleShowPostingStrategyQuiz}/>
                       {isInternalUser && <NewBusinessButton/>}
                       {<AmplitudeFeatureFlagVariablesLogView/>}
                       {<Typography variant="subtitle2">App Version: {ALKAI_VERSION}</Typography>}
                       {showPostingStrategyQuiz && <PostingStrategyQuizFullScreenDialog externalStateOpen={showPostingStrategyQuiz}
                                                                                        handleClose={closePostingStrategyQuiz}
                                                                                        modalDialogSxProps={{ maxWidth: "430px", mx: "auto" }}
                       />}
                     </Stack>
    }


    {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS}
                                               shouldShowSignIn={showCreateLogin}
                                               onClose={handleDialogOnClose}/>}
    <AlertBanner/>

    <ConfirmDialog title={"Want to remove a business from your subscription?"}
                   dialogContent={removeBusinessWarmupDialogContent}
                   open={showRemoveBusinessWarmup}
                   showNeedHelpLink={true}
                   confirmText={"Remove business"}
                   onConfirm={handleConfirmRemoveBusiness}
                   onClose={handleCancelRemoveBusiness}/>

    <ConfirmDialog title={"Your subscription is scheduled to change"}
                   dialogContent={removeBusinessSuccessDialogContent}
                   open={showRemoveBusinessSuccessDialog}
                   confirmText={"Ok"}
                   onConfirm={handleAcknowledgeRemoveBusinessSuccessDialog}
                   onClose={handleAcknowledgeRemoveBusinessSuccessDialog}/>

    <ConfirmDialog title={"Please contact support"}
                   dialogContent={getFormattedMessageForContactSupportDialog()}
                   open={showAcknowledgeScheduledDowngradeDialog}
                   confirmText={"Contact support"}
                   onConfirm={handleContactSupportClicked}
                   onClose={handleCloseAcknowledgeScheduledDowngradeDialog}/>

    <ConfirmDialog title={"Billing portal update"}
                   dialogContent={getFormattedMessageForBillingPortalWarningDialog()}
                   open={showBillingPortalWarningDialog}
                   confirmText={"Continue"}
                   onConfirm={handleBillingPortalWarningDialogContinue}
                   onClose={handleCloseBillingPortalWarningDialog}/>

    <ConfirmDialog title={"Subscription Management"}
                   dialogContent={getFormattedMessageForSubscriptionManagementWarningDialog()}
                   open={showSubscriptionManagementWarningDialog}
                   confirmText={"Got it"}
                   onConfirm={handleCloseSubscriptionManagementWarningDialog}
                   onClose={handleCloseSubscriptionManagementWarningDialog}/>


  </Stack>
}
