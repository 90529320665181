/* eslint-disable react-hooks/rules-of-hooks */

import { amplitudeFeatureFlagsWrapper } from "./amplitudeFeatureFlagsWrapper";

let injectableAmplitudeFeatureFlagsWrapper: typeof amplitudeFeatureFlagsWrapper;
export const GRANDFATHERED_DEFAULT_TO_PLAN_TAB = "grandfathereddefaulttoplantab";
export const SHOULD_PROMPT_FOR_IOS_RATING = "should_prompt_for_ios_rating";
export const USE_PLAN_BRIEF = "use_plan_brief";
export const IN_MARCH_2025_PRICING_TEST = "in_march_2025_pricing_test";

const BOOLEAN_FEATURE_FLAGS: string[] = [GRANDFATHERED_DEFAULT_TO_PLAN_TAB,
                                         SHOULD_PROMPT_FOR_IOS_RATING,
                                         USE_PLAN_BRIEF,
                                         IN_MARCH_2025_PRICING_TEST];

async function getAmplitudeVariablesForSignIn()
{
  const usePlanBriefValue = await usePlanBrief()

  return {
    use_plan_brief: usePlanBriefValue,
  }
}

function shouldDefaultToPlanTab()
{
  return isFeatureEnabled( GRANDFATHERED_DEFAULT_TO_PLAN_TAB )
}

function shouldAllowUserUploadedMusic()
{
  return true;
}

function shouldShowWaveformStartTimeSelection()
{
  return true;
}

function shouldAllowVideoAudioMusic()
{
  return true;
}

function shouldShowMusicFavorites()
{
  return true;
}

function promptForIosRatingFeatureEnabled()
{
  return isFeatureEnabled( SHOULD_PROMPT_FOR_IOS_RATING )
}

function usePlanBrief()
{
  return isFeatureEnabled( USE_PLAN_BRIEF )
}

function inMarch2025PricingTest()
{
  return isFeatureEnabled( IN_MARCH_2025_PRICING_TEST );
}

function isFeatureEnabled( name: string ): Promise<boolean>
{
  return getWrapper().isFeatureFlagEnabled( name );
}

function getWrapper()
{
  return injectableAmplitudeFeatureFlagsWrapper ? injectableAmplitudeFeatureFlagsWrapper : amplitudeFeatureFlagsWrapper;
}

export const amplitudeFeatureFlagsVariables = {
  BOOLEAN_FEATURE_FLAGS,
  getAmplitudeVariablesForSignIn,
  shouldDefaultToPlanTab,
  shouldShowMusicFavorites,
  isFeatureEnabled,
  shouldAllowUserUploadedMusic,
  shouldShowWaveformStartTimeSelection,
  shouldAllowVideoAudioMusic,
  promptForIosRatingFeatureEnabled,
  usePlanBrief,
  inMarch2025PricingTest,
};
