import React from "react";
import { SavingsOutlined } from "@mui/icons-material";
import { UserSettingsRow } from "./userSettingsRow";
import { ALTERNATE_PRICING_YEARLY_SAVINGS_DOLLAR_AMOUNT } from "../../constants";
import { useAmplitudeFeatureFlag } from "../amplitude/useAmplitudeFeatureFlag";
import { amplitudeFeatureFlagsVariables } from "../amplitude/amplitudeFeatureFlagsVariables";

export interface UpgradeUserSettingsRowProps
{
  onClick?: ( e: React.MouseEvent ) => void;
}

export function UpgradeUserSettingsRow( props: UpgradeUserSettingsRowProps )
{
  let showAlternatePricing = useAmplitudeFeatureFlag( amplitudeFeatureFlagsVariables.inMarch2025PricingTest );

  function upgradeToYearlyLabel()
  {
    let upgradeToYearlyLabel = "Switch to yearly and save $240";
    if ( showAlternatePricing )
    {
      upgradeToYearlyLabel = `Switch to yearly and save ${ALTERNATE_PRICING_YEARLY_SAVINGS_DOLLAR_AMOUNT}`;
    }
    return upgradeToYearlyLabel;
  }

  return <UserSettingsRow icon={<SavingsOutlined/>}
                          label={upgradeToYearlyLabel()}
                          onClick={props.onClick}/>
}
