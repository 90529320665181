import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import { BouncingDotsLoader } from "../loadingIndicator/BouncingDotsLoader";
import {
  KEYBOARD_ENTER_KEY_ID,
  MediaAssetSource,
  STABILITY_STYLE_PRESET_CONSTANTS,
  STABILITY_STYLE_PRESETS,
  STABLE_DIFFUSION_SOURCE_MEDIA_ASSET
} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { clearAlertMessage, errorAlert, setAlertMessage, successAlert } from "../alert/alertSlice";
import { capitalize, concat, filter, includes, isEmpty, join, map, replace, size, split } from "lodash";
import { BusinessMediaStyleAPI, businessServices, MediaStylesAPI } from "../../services/business.services";
import { eventTracker } from "../../helpers/eventTracker";
import { shouldBlockForUpsell } from "../user/userSlice";
import { RootState } from "../../app/store";
import { setUpsellShown } from "../ui/uiSlice";
import { UPSELL_SOURCE_GENERATE_IMAGE } from "../../helpers/trackingConstants";
import AlertBanner from '../alert/alertBanner';
import { logToConsoleError } from "../utils/devLoggingHelper";

export interface StableDiffusionImageGeneratorDialogProps
{
  id: string;
  keepMounted: boolean;
  onClose: ( url?: string, mediaSource?: MediaAssetSource ) => void;
  search_term: string | undefined;
  openedFromMediaLibraryTab?: boolean;
}

function StableDiffusionImageGeneratorDialog( props: StableDiffusionImageGeneratorDialogProps )
{
  const { onClose } = props;
  const [mediaUrl, setMediaUrl] = useState<string>();
  const [selectedStyle, setSelectedStyle] = useState( STABILITY_STYLE_PRESET_CONSTANTS.PRESET_PHOTOGRAPHIC );
  const [requestLoading, setRequestLoading] = useState( false );
  const [termToSearch, setTermToSearch] = useState( props.search_term );
  const [combinedStyles, setCombinedStyles] = useState<string[]>( STABILITY_STYLE_PRESETS );
  const [customStyles, setCustomStyles] = useState<string[]>( [] );
  const blockForUpsell = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const dispatch = useDispatch();
  const isPromptInvalid = size( termToSearch ) >= 2000;

  useEffect( () =>
  {
    setTermToSearch( props.search_term )
  }, [props.search_term] );

  useEffect( () =>
  {
    getBusinessMediaStyles();
    return () =>
    {
      dispatch( clearAlertMessage() );
    }
  }, [] )

  function getBusinessMediaStyles()
  {
    setRequestLoading( true );
    businessServices.getMediaStyles().then( ( data: MediaStylesAPI ) =>
    {
      const mediaStyles = data.media_styles;
      const activeMediaStyles = filter( mediaStyles, ( mediaStyle ) => mediaStyle.active );
      const customStyles = map( activeMediaStyles, removeCustomPrefixAndDasherize );

      if ( !!customStyles && customStyles.length > 0 )
      {
        const filteredInitialStyles = filter( STABILITY_STYLE_PRESETS, ( style ) => !includes( customStyles, style ) )
        const allStyles = concat( customStyles, filteredInitialStyles );
        setCombinedStyles( allStyles );
        setCustomStyles( customStyles );
        if ( size( customStyles ) > 0 )
        {
          setSelectedStyle( customStyles[0] );
        }
      }
    } ).catch( ( error ) =>
    {
      logToConsoleError( error );
    } ).finally( () =>
    {
      setRequestLoading( false );
    } );
  }

  function removeCustomPrefixAndDasherize( mediaStyle: BusinessMediaStyleAPI )
  {
    return replace( replace( mediaStyle.preset_name, "custom_", "" ), new RegExp("_","g"), "-" )
  }

  const handleRegenerate = async () =>
  {
    await requestAIImage();
  };

  const handleOk = () =>
  {
    onClose( mediaUrl, STABLE_DIFFUSION_SOURCE_MEDIA_ASSET );
  };

  const handleGenerateClick = () =>
  {
    if ( blockForUpsell )
    {
      dispatch( setUpsellShown( true ) );
      eventTracker.logUpsellShown( UPSELL_SOURCE_GENERATE_IMAGE );
    }
    else
    {
      requestAIImage();
    }
  }

  const requestAIImage = () =>
  {
    if ( !!termToSearch )
    {
      setMediaUrl( undefined );
      setRequestLoading( true );
      businessServices.generateAiImage( { text: termToSearch, style_preset: selectedStyle } ).then( ( mediaAssetAPI ) =>
      {
        if ( !!mediaAssetAPI )
        {
          if ( props.openedFromMediaLibraryTab )
          {
            eventTracker.logMediaLibraryAiGenerateImageCreated( mediaAssetAPI.id, termToSearch, selectedStyle );
            dispatch( setAlertMessage( successAlert( "Image added to your Media Library", "top" ) ) );
          }
          else
          {
            eventTracker.logEditPostMediaAiGenerateImageCreated( mediaAssetAPI.id, termToSearch, selectedStyle );
          }
          setMediaUrl( mediaAssetAPI.url );
        }
      } ).catch( ( error ) =>
      {
        logToConsoleError( error );
        dispatch( setAlertMessage( errorAlert( "An error occurred while generating the image" ) ) );
      } ).finally( () =>
      {
        setRequestLoading( false );
      } );
    }
  }

  const handleChangePrompt = ( event: React.ChangeEvent<HTMLInputElement> ) =>
  {
    setTermToSearch( event.target.value )
  };

  const handleKeyUp = ( event: { which: number; preventDefault: () => void; } ) =>
  {
    if ( event.which === KEYBOARD_ENTER_KEY_ID )
    {
      event.preventDefault();
      handleRegenerate();
    }
  };

  function getHelperText()
  {
    if ( isPromptInvalid )
    {
      return "Prompt must be under 2000 characters";
    }
    return "";
  }

  const handleStyleChange = ( event: SelectChangeEvent ) =>
  {
    setSelectedStyle( event.target.value );
  }

  function getGenerateButtonVariant()
  {
    if ( props.openedFromMediaLibraryTab )
    {
      return "contained";
    }
    return mediaUrl ? "outlined" : "contained";
  }

  function shouldDisableGenerateButton()
  {
    return requestLoading || isPromptInvalid || isEmpty( termToSearch );
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Stack>
        <FormControl margin={"normal"} fullWidth>
          <TextField value={termToSearch}
                     label="Generate AI Image using this prompt"
                     error={isPromptInvalid}
                     helperText={getHelperText()}
                     onChange={handleChangePrompt} onKeyUp={handleKeyUp}/>
        </FormControl>

        <FormControl margin={"normal"} fullWidth>
          <InputLabel id="styleLabel">Style</InputLabel>
          <Select
            labelId="styleSelect"
            id="styleSelect"
            value={selectedStyle}
            defaultValue={selectedStyle}
            label="Style"
            onChange={handleStyleChange}
          >
            {
              map( combinedStyles, ( stylePreset ) =>
              {
                let highlightCustomStyle = {}
                if ( includes( customStyles, stylePreset ) )
                {
                  highlightCustomStyle = { fontWeight: "bold" }
                }
                return <MenuItem key={stylePreset} sx={highlightCustomStyle} value={stylePreset}>{capitalize(
                  join( split( stylePreset, "-" ), " " ) )}</MenuItem>;
              } )
            }
          </Select>
        </FormControl>
      </Stack>

      <Box sx={{ overflow: "auto" }}>
        {mediaUrl && <MediaItem url={mediaUrl}/>}
        {requestLoading && <BouncingDotsLoader/>}
      </Box>
      <Button variant={getGenerateButtonVariant()}
              disabled={shouldDisableGenerateButton()}
              onClick={handleGenerateClick}
              sx={{ mb: 4, mt: 4, mr: 4 }}>Generate</Button>
      {!props.openedFromMediaLibraryTab && mediaUrl && <Button variant={"contained"} onClick={handleOk} sx={{ mb: 4, mt: 4, mr: 4 }}>Use this
                                                                                                                                     image</Button>}
      <AlertBanner/>
    </Box>
  );
}

export default StableDiffusionImageGeneratorDialog;
