import { store } from "../app/store";
import { ABORT_ERROR, makeAuthenticatedRequest, postJsonToApi, UNAUTHORIZED_ERROR } from "./requestManager";
import { currentUserBusinessId, getFirstPostDrafted, updateBusiness } from "../features/business/businessSlice";
import { differenceWith, isObject, last, map, merge, reverse } from "lodash";
import {
  addPostIdeaMessage,
  clearAssistantWaitingType,
  getLatestPostIdeaMessageTimestamp,
  getMessages,
  getPostIdeaMessages,
  hasPostIdeaMessages,
  MediaSelection,
  MessageAPI,
  PostIdeaDataAPI,
  setAssistantType,
  setAssistantWaitingType,
  setCheckMessagesFailedAllAttempts,
  setEnsureChatThread,
  setMessages,
  setPlanBriefUpdatedMessages,
  setPostIdeaMessages,
  setSelectedMedia,
  setShowChooseMediaButton,
  setWaitingForThreadRunToComplete,
} from "../features/assistantChat/assistantChatSlice";
import { addPost } from "../features/postIdea/postsSlice";
import { RefreshBusinessAPI } from "./business.services";
import { eventTracker } from "../helpers/eventTracker";
import { ASSISTANT_CHAT_CHECK_MESSAGES_ATTEMPTS, MESSAGE_ROLE_ASSISTANT } from "../features/constants";
import { errorReporter } from "../features/error/errorReporter";
import { isUserLoggedInAndVerified } from "../features/user/userSlice";
import { authenticationServices } from "./authentication.services";
import { getGAClientId } from "../features/tracking/trackingSlice";
import { addOrUpdatePostIdeaFromMessage, getOutputFormatDisplayNameFromSlug } from "../features/postIdea/postIdeaHelper";

export const chatServices = {
  clearCheckMessagesTimeout,
  clearLongRunningFunctionTimeout,
  sendMessage,
  completeChooseMedia,
  checkForNewMessages,
  checkForNewMessagesOnCurrentThread,
  checkForNewMessagesIfLoggedIn,
  hasCheckMessageTimeout,
  fetchAllPostUrls,
}
type WaitingType = "creating_post_idea" | "getting_website";

export interface SendMessageResponseAPI
{
  chat_messages: ChatMessage[];
  post_idea_messages?: MessageAPI[];
  plan_brief_updated_messages?: MessageAPI[];
  business_data?: RefreshBusinessAPI;
  choose_media_requested?: boolean;
  failed_to_add_message?: boolean;
  is_completed?: boolean;
  assistant_type?: string;
  waiting_type?: WaitingType;
}

interface AlkaiServiceError
{
  error: string;
  retry?: boolean;
  ensure_chat_thread?: boolean;
}

export interface CheckLongRunningFunctionResponseAPI
{
  is_completed: boolean;
  waiting_type?: WaitingType;
}

export interface ChatMessage
{
  id: string;
  created_at: number;
  role: string;
  content: ChatContent[];
}

type ChatContentType = "text" | "image_file" | "image_url";

export interface ChatContent
{
  type: ChatContentType;
  text: ChatText;
}

export interface ChatText
{
  value: string;
}

async function sendMessage( message: string, additionalData: {} = {} )
{
  const endPoint = "chat/send_message";
  const state = store.getState();
  const googleClientId = getGAClientId( state );
  const moreData = merge( additionalData, { ga_client_id: googleClientId } );

  if ( !!currentUserBusinessId( state ) )
  {
    return send( message, moreData, endPoint );
  }
  else
  {
    errorReporter.reportErrorToSentry( new Error( "Current business is not set" ) );
    return Promise.reject( "Current business is not present!" );
  }
}

async function completeChooseMedia( message: string, selectedMedia: MediaSelection[] )
{
  const state = store.getState();

  if ( !currentUserBusinessId( state ) )
  {
    errorReporter.reportErrorToSentry( new Error( "Current business is not set" ) );
    return Promise.reject( "Current business is not present!" );
  }

  const latestPostIdeaMessageTimestamp = getLatestPostIdeaMessageTimestamp( state );

  const mediaParams = map( selectedMedia, ( medium ) =>
  {
    return {
      url: medium.url,
      source: medium.source
    }
  } )

  const additionalData = {
    selected_media: mediaParams,
    latest_post_idea_message_timestamp: latestPostIdeaMessageTimestamp,
  };
  const endPoint = "chat/complete_choose_media";
  return send( message, additionalData, endPoint );
}

async function send( message: string, additionalData: {}, endPoint: string )
{
  const state = store.getState();

  const jsonBody = merge( additionalData, {
    message: message,
    business_id: currentUserBusinessId( state ),
  } );

  const response = await postJsonToApi<SendMessageResponseAPI>( endPoint, {}, jsonBody );

  const checkCount = 0;

  return await processMessagesResponse( response, checkCount, additionalData );
}

function hasCheckMessageTimeout()
{
  return !!window.checkMessageTimeout;
}

function hasLongRunningFunctionTimeout()
{
  return !!window.longRunningFunctionTimeout;
}

function clearCheckMessagesTimeout()
{
  if ( hasCheckMessageTimeout() )
  {
    clearTimeout( window.checkMessageTimeout );
    window.checkMessageTimeout = null;
  }
}

function clearLongRunningFunctionTimeout()
{
  if ( hasLongRunningFunctionTimeout() )
  {
    clearTimeout( window.longRunningFunctionTimeout );
    window.longRunningFunctionTimeout = null;
  }
}

async function setupCheckMessageTimeout( checkCount: number, additionalData?: {} )
{
  if ( checkCount < ASSISTANT_CHAT_CHECK_MESSAGES_ATTEMPTS )
  {
    clearCheckMessagesTimeout();

    window.checkMessageTimeout = setTimeout( () =>
    {
      checkForNewMessages( checkCount, additionalData );
    }, 1000 );

  }
  else
  {
    handleCheckMessagesFailedAllAttempts();
  }
}

async function setupCheckLongRunningFunctionTimeout()
{
  clearLongRunningFunctionTimeout();
  window.longRunningFunctionTimeout = setTimeout( () =>
  {
    checkLongRunningFunction();
  }, 3000 );
}

function handleCheckMessagesFailedAllAttempts()
{
  store.dispatch( setWaitingForThreadRunToComplete( false ) );
  store.dispatch( setCheckMessagesFailedAllAttempts( true ) );
}

async function processMessagesResponse( response: SendMessageResponseAPI, checkCount: number, additionalData: {} )
{
  if ( response.choose_media_requested )
  {
    store.dispatch( setShowChooseMediaButton( true ) )
  }

  processChooseMedia( response );
  processChatMessages( response );
  processBusinessData( response );
  processAssistantType( response );
  processWaitingType( response );
  processPlanBriefUpdatedMessages( response );
  await processPostIdeaMessages( response );

  store.dispatch( setWaitingForThreadRunToComplete( !response.is_completed ) );

  if ( !response.is_completed )
  {
    await setupCheckMessageTimeout( checkCount, additionalData );
  }
  else
  {
    clearCheckMessagesTimeout();
    store.dispatch( setWaitingForThreadRunToComplete( false ) );
    store.dispatch( clearAssistantWaitingType() );
  }

  return !response.failed_to_add_message;
}

async function checkForNewMessagesIfLoggedIn()
{
  if ( isUserLoggedInAndVerified( store.getState() ) )
  {
    return checkForNewMessagesOnCurrentThread();
  }
  else
  {
    return false;
  }
}

async function checkForNewMessagesOnCurrentThread()
{
  if ( !!currentUserBusinessId( store.getState() ) )
  {
    return checkForNewMessages( 0 );
  }
  else
  {
    errorReporter.reportErrorToSentry( new Error( "refresh_business endpoint is called unexpectedly since current business is not set" ) );
  }
  return false;
}

function isErrorWithName( error: any, name: string )
{
  return !!error && (error instanceof Error) && error.name === name;
}

function shouldStopAutomaticallyRetrying( error: AlkaiServiceError )
{
  return isObject( error ) && error.retry === false;
}

async function checkForNewMessages( checkCount: number, additionalData: {} = {} )
{
  const endPoint = "chat/get_messages";
  const state = store.getState();
  const latestPostIdeaMessageTimestamp = getLatestPostIdeaMessageTimestamp( state );
  const jsonBody = merge( additionalData, {
    business_id: currentUserBusinessId( state ),
    latest_post_idea_message_timestamp: latestPostIdeaMessageTimestamp
  } );

  try
  {
    await setupCheckLongRunningFunctionTimeout();
    const response = await postJsonToApi<SendMessageResponseAPI>( endPoint, {}, jsonBody );
    clearLongRunningFunctionTimeout();
    return await processMessagesResponse( response, checkCount + 1, additionalData );
  }
  catch (error)
  {
    if ( error !== UNAUTHORIZED_ERROR && !isErrorWithName( error, ABORT_ERROR ) )
    {
      const alkaiError = error as AlkaiServiceError;
      if ( shouldStopAutomaticallyRetrying( alkaiError ) )
      {
        handleCheckMessagesFailedAllAttempts();
        if ( alkaiError?.ensure_chat_thread )
        {
          store.dispatch( setEnsureChatThread( true ) );
        }
      }
      else
      {
        await setupCheckMessageTimeout( checkCount + 1, additionalData );
      }
    }
  }
  finally
  {
    clearLongRunningFunctionTimeout();
  }
}

async function checkLongRunningFunction()
{
  const endPoint = "chat/check_long_running_function";
  const state = store.getState();
  const jsonBody = {
    business_id: currentUserBusinessId( state ),
  };
  const response = await postJsonToApi<CheckLongRunningFunctionResponseAPI>( endPoint, {}, jsonBody );
  processWaitingType( response );
}

function processChatMessages( response: SendMessageResponseAPI )
{
  // Comes in from the server in time-descending order
  const chatMessages = response.chat_messages;
  const sortedMessages = reverse( chatMessages );
  sendEventForNewAssistantMessageReceived( sortedMessages );

  store.dispatch( setMessages( sortedMessages ) );
}

function processPlanBriefUpdatedMessages( response: SendMessageResponseAPI )
{
  const planBriefUpdatedMessages = response.plan_brief_updated_messages;
  if ( !!planBriefUpdatedMessages )
  {
      store.dispatch( setPlanBriefUpdatedMessages( planBriefUpdatedMessages ) );
  }
  else
  {
    store.dispatch( setPlanBriefUpdatedMessages( [] ) );
  }
}

function sendEventForNewAssistantMessageReceived( sortedMessages: ChatMessage[] )
{
  const previousMessagesState = getMessages( store.getState() );

  const lastMessageInState = last( previousMessagesState );
  const lastMessageFromResponse = last( sortedMessages );

  const lastMessageInStateCreatedAt = lastMessageInState?.created_at;
  const lastMessageFromResponseCreatedAt = lastMessageFromResponse?.created_at;

  if ( !!lastMessageInStateCreatedAt &&
       !!lastMessageFromResponseCreatedAt &&
       lastMessageFromResponseCreatedAt > lastMessageInStateCreatedAt &&
       lastMessageFromResponse?.role === MESSAGE_ROLE_ASSISTANT )
  {
    eventTracker.logAssistantMessageReceived();
  }
}

function processAssistantType( response: SendMessageResponseAPI )
{
  if ( !!response.assistant_type )
  {
    store.dispatch( setAssistantType( response.assistant_type ) );
  }
}

function processWaitingType( response: SendMessageResponseAPI | CheckLongRunningFunctionResponseAPI )
{
  if ( !!response.waiting_type )
  {
    store.dispatch( setAssistantWaitingType( response.waiting_type ) );
  }
  else
  {
    store.dispatch( clearAssistantWaitingType() );
  }
}

async function processPostIdeaMessages( response: SendMessageResponseAPI )
{
  const postIdeaMessagesInPayload = response.post_idea_messages || [];

  if ( postIdeaMessagesInPayload.length > 0 )
  {
    const state = store.getState();
    const previousPostIdeaMessages = getPostIdeaMessages( state );
    if ( !hasPostIdeaMessages( state ) )
    {
      store.dispatch( setPostIdeaMessages( postIdeaMessagesInPayload ) );
      map( postIdeaMessagesInPayload, ( message ) => addOrUpdatePostIdeaFromMessage( message ) );
    }
    else
    {
      postIdeaMessagesInPayload.forEach( ( message ) =>
      {
        store.dispatch( addPostIdeaMessage( message ) );
        addOrUpdatePostIdeaFromMessage( message );
      } );
    }
    store.dispatch( setSelectedMedia( null ) );

    const newPostIdeaMessages = differenceWith( postIdeaMessagesInPayload, previousPostIdeaMessages, ( msg1, msg2 ) =>
    {
      return msg1.id === msg2.id;
    } )

    if ( !!newPostIdeaMessages && newPostIdeaMessages.length > 0 )
    {
      const lastMessage = last( newPostIdeaMessages );
      if ( lastMessage )
      {
        const messageData = lastMessage.data instanceof Array ? lastMessage.data[0] as PostIdeaDataAPI : lastMessage.data as PostIdeaDataAPI;
        const outputFormat = getOutputFormatDisplayNameFromSlug( messageData.output_format_slug );
        if ( !getFirstPostDrafted( store.getState() ) )
        {
          await authenticationServices.refreshUser();
          eventTracker.logFirstPostPreviewShown( messageData.id, messageData.topic?.name, messageData.topic?.source, outputFormat );
        }
        else
        {
          eventTracker.logPostPreviewShown( messageData.id, messageData.topic?.name, messageData.topic?.source, outputFormat );
        }
      }
    }
  }
}

function processBusinessData( response: SendMessageResponseAPI )
{
  const businessData = response.business_data;
  if ( !!businessData )
  {
    store.dispatch( updateBusiness( businessData ) );
  }
}

function processChooseMedia( response: SendMessageResponseAPI )
{
  if ( response.choose_media_requested )
  {
    store.dispatch( setShowChooseMediaButton( true ) )
  }
}

function fetchAllPostUrls( postUrlsToFetch: string[] )
{
  return Promise.all( postUrlsToFetch.map(
    ( postUrl ) => makeAuthenticatedRequest( { baseUrl: postUrl, endPoint: "", methodType: "GET" } ) ) )
    .then( ( postJsons ) =>
    {
      if ( postJsons.length > 0 )
      {
        postJsons.forEach( ( postJson ) =>
        {
          store.dispatch( addPost( postJson ) )
        } )
      }
    } )
}


