import { createSlice } from '@reduxjs/toolkit'
import { RootState } from "../../app/store";
import { isUndefined, omit } from "lodash";
import {
  IosWarmupRatingInteraction,
  RatingDialogShownSourceType,
  SIGN_IN_PROVIDER_PHONE_NUMBER,
  SUBSCRIPTION_DURATION_MONTHLY,
  SUBSCRIPTION_PLATFORM_IOS,
  SUBSCRIPTION_PLATFORM_STRIPE,
  SubscriptionDuration,
  SubscriptionPlatform
} from "../constants";
import { authHelper } from "../../helpers/authHelper";

export interface UserState
{
  auth: UserAuthData | null;
  verification_required: string | null;
  has_finished_posts: boolean;
  is_internal_user?: boolean;
  slug?: string;
  is_guest?: boolean;
  phone_number?: string;
  email?: string;
  is_subscribed?: boolean;
  has_prior_subscription?: boolean;
  subscription_platform?: SubscriptionPlatform;
  subscription_duration?: SubscriptionDuration;
  remaining_free_posts: number | null;
  number_of_businesses?: number;
  max_number_of_businesses?: number;
  last_ios_rating_warmup?: LastIosRatingWarmup;
}

export interface LastIosRatingWarmup
{
  last_ios_rating_warmup_interaction_type: IosWarmupRatingInteraction;
  last_ios_rating_warmup_interaction_shown_date: string;
  last_ios_rating_warmup_source: RatingDialogShownSourceType;
}

const initialState: UserState = {
  auth: null,
  verification_required: null,
  has_finished_posts: false,
  remaining_free_posts: null,
}

export interface UserAuthData
{
  accessToken: string | null
  tokenType: string | null;
  client: string | null;
  expiry: string | null;
  uid: string | null;
}

export const userSlice = createSlice( {
  name: 'user',
  initialState,
  reducers: {
    setUser: ( state, action ) =>
    {
      const newState = {
        ...state,
        ...action.payload
      }
      if ( state.is_guest && !action.payload.is_guest )
      {
        return omit( newState, 'is_guest' );
      }
      else
      {
        return newState;
      }
    },
    updateUserVerificationRequired: ( state, action ) =>
    {
      return {
        ...state,
        verification_required: action.payload
      }
    },
    clearUserData: () =>
    {
      return {
        ...initialState
      }
    },
  },
} )

function getAuth( state: RootState ): UserAuthData | null
{
  const authData = authHelper.getAuthFromLocalStorage();
  if ( authData )
  {
    return authData;
  }
  else
  {
    return state.user.auth;
  }
}

export const isUserLoggedIn = ( state: RootState ) =>
{
  const authData = getAuth( state );
  const token = authData?.accessToken;
  return !!token;
}

export const hasMultipleBusinesses = ( state: RootState ) => !isUndefined( state.user.number_of_businesses ) && state.user.number_of_businesses > 1;
export const hasRemainingFreePosts = ( state: RootState ) => state.user.remaining_free_posts !== null && state.user.remaining_free_posts >= 0;
export const shouldBlockForUpsell = ( state: RootState ) => hasRemainingFreePosts( state ) && state.user.remaining_free_posts === 0;
export const getRemainingFreePosts = ( state: RootState ) => state.user.remaining_free_posts;
export const getNumberOfBusinesses = ( state: RootState ) => state.user.number_of_businesses;
export const isUserLoggedInAndVerified = ( state: RootState ) => isUserLoggedIn( state ) && !isUserVerificationRequired( state );
export const isUserVerificationRequired = ( state: RootState ) => !!state.user.verification_required;

export const getMaxNumberOfBusinesses = ( state: RootState ) => state.user.max_number_of_businesses;

export const getUserVerificationType = ( state: RootState ) => state.user.verification_required;
export const isPhoneVerificationRequired = ( state: RootState ) => (getUserVerificationType( state ) === SIGN_IN_PROVIDER_PHONE_NUMBER);

export const getUserWithoutAuthData = ( state: RootState ) =>
{
  const { auth, ...remaining } = state.user;
  return remaining;
}

export const getUserAuthData = ( state: RootState ) => getAuth( state );
export const getHasFinishedPosts = ( state: RootState ) => state.user.has_finished_posts;
export const getUserSlug = ( state: RootState ) => state.user.slug;
export const getUserPhoneNumber = ( state: RootState ) => state.user.phone_number;
export const getUserEmail = ( state: RootState ) => state.user.email;

export const getIsInternalUser = ( state: RootState ) => !!state.user.is_internal_user;
export const isGuestUser = ( state: RootState ) => !!state.user.is_guest;

export const isSubscribedUser = ( state: RootState ) => !!state.user.is_subscribed;
export const wasSubscribedUser = ( state: RootState ) => !!state.user.has_prior_subscription;

export const hasPhoneNumber = ( state: RootState ) => !!state.user.phone_number;
export const hasEmail = ( state: RootState ) => !!state.user.email;
export const hasIosSubscription = ( state: RootState ) => state.user.subscription_platform === SUBSCRIPTION_PLATFORM_IOS;
export const hasStripeSubscription = ( state: RootState ) => state.user.subscription_platform === SUBSCRIPTION_PLATFORM_STRIPE;
export const subscriptionDuration = ( state: RootState ) => state.user.subscription_duration;
export const subscriptionPlatform = ( state: RootState ) => state.user.subscription_platform;
export const isMonthlySubscriber = ( state: RootState ) => isSubscribedUser( state )
                                                           && subscriptionDuration( state ) === SUBSCRIPTION_DURATION_MONTHLY;
export const isMonthlyStripeSubscriber = ( state: RootState ) => isSubscribedUser( state )
                                                                 && hasStripeSubscription( state )
                                                                 && isMonthlySubscriber( state );

// Action creators are generated for each case reducer function
export const {
  setUser,
  clearUserData,
  updateUserVerificationRequired,
} = userSlice.actions

export default userSlice.reducer
