import { Link, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { ALKAI_FAQ_CONFIGURE_PLAN_URL, ROUTE_SEO, ROUTES } from "../constants";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import React, { useEffect, useState } from "react";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import AlertBanner from "../alert/alertBanner";
import { WeeklySchedulePicker } from "./weeklySchedulePicker";
import { businessServices, PlanSettingsAPI } from "../../services/business.services";
import { useDispatch } from "react-redux";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { PlanOutputFormatsPicker } from "./planOutputFormatsPicker";
import { PlanBriefSection } from "../planner/PlanBriefSection";
import { parseISO } from "date-fns";
import { PLAN_BRIEF_SOURCE_PLAN_SETTINGS } from "../../helpers/trackingConstants";

export function PlanSettingsPage()
{
  const dispatch = useDispatch();
  const [loadingPlanSettings, setLoadingPlanSettings] = useState( false );
  const [planSettings, setPlanSettings] = useState<PlanSettingsAPI>();
  const upcomingPlanBrief = planSettings?.plan_brief;
  const upcomingPlanBriefDate = !!upcomingPlanBrief ? parseISO( upcomingPlanBrief.week_start_date ) : undefined;

  useEffect( () =>
  {
    onMount();
  }, [] );

  async function onMount()
  {
    setLoadingPlanSettings( true );
    try
    {
      const planSettings = await businessServices.getPlanSettings();
      setPlanSettings( planSettings );
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "There was a problem loading plan settings", "top" ) ) );
    }
    finally
    {
      setLoadingPlanSettings( false );
    }
  }

  const navigateWithSearchParams = useNavigateWithSearchParams();

  async function handleBackClicked()
  {
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  return (
    <Stack spacing={5} sx={{ textAlign: "left", pt: 5 }} alignItems="start">
      <Helmet>
        <title>{ROUTE_SEO.PLAN_SETTINGS.title}</title>
        <meta name="description" content={ROUTE_SEO.PLAN_SETTINGS.description}/>
      </Helmet>
      <AlertBanner/>
      <Stack direction="row" sx={{ width: "100%", px: 5 }}>
        <IconButton onClick={handleBackClicked} sx={{ padding: "unset", position: "absolute" }}>
          <ArrowBackIcon/>
        </IconButton>
        <Typography sx={{ textAlign: "center", flex: "1 1 0", width: "100%" }}>Plan settings</Typography>
      </Stack>

      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" spacing={5} alignItems="center" sx={{ background: "#FFEC3D38", width: "100%", p: 5, border: "3px solid #FFEC3D" }}>
          <InfoOutlinedIcon/>
          <Typography>Get help configuring your plan <Link sx={{ color: "black" }} href={ALKAI_FAQ_CONFIGURE_PLAN_URL} rel="noopener"
                                                           target="_blank">here</Link>
          </Typography>
        </Stack>
      </Stack>
      {loadingPlanSettings && <CircularProgress/>}
      {
        !loadingPlanSettings && !planSettings?.plan_ready &&
        <Stack sx={{ px: 10, justifyContent: "center", alignItems: "center", textAlign: "center", width: "100%" }} spacing={4}>
          <Typography variant="caption">Your plan is not ready yet. Please check back later.</Typography>
        </Stack>
      }
      {
        !loadingPlanSettings && planSettings?.plan_ready &&
        <Stack sx={{ px: 10 }} spacing={4}>
          <Typography variant="caption">Changes made to your plan settings will be reflected the next time your plan is extended.</Typography>
          <Stack sx={{ pt: 10 }}>
            {!!upcomingPlanBrief && !!upcomingPlanBriefDate && <PlanBriefSection planBrief={upcomingPlanBrief}
                                                                                 weekStart={upcomingPlanBriefDate}
                                                                                 showSimplifiedPlanBrief={true}
                                                                                 sxProps={{ pb: 10 }}
                                                                                 source={PLAN_BRIEF_SOURCE_PLAN_SETTINGS}/>}
            <Typography variant="h6">Days of the week</Typography>
            <Typography>Alkai will plan 1 post for each day selected</Typography>
            <WeeklySchedulePicker weeklySchedule={planSettings.weekly_schedule}/>
          </Stack>

          <Stack sx={{ mt: 10 }}>
            <Typography variant="h6">Formats</Typography>
            <Typography>Alkai will include the following formats in your plan. You can always switch formats in the Plan before the post is
                        generated.</Typography>
            <PlanOutputFormatsPicker businessOutputFormats={planSettings.output_formats}/>
          </Stack>
        </Stack>
      }
    </Stack>);
}
